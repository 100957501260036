import React, { useEffect, useState } from 'react';
import {
  Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel,
  FormHelperText, Grid, InputLabel, TextField, Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import {
  statuses, types, fetchProject, stageTypes, activitiesPerType, createProject,
  patchProject,
} from './fetchData';
import StagesTable from './subtable';
import { activities } from 'layouts/TimeSheet/fetchData';

const ProjectFormModal = ({ open, onClose, onReset, onSubmit, id }) => {

  const [name, setName] = useState('');
  const [financialProject, setFinancialProject] = useState('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(null);
  const [duration, setDuration] = useState(0);
  const [projectType, setProjectType] = useState(null);
  const [status, setStatus] = useState(null);

  const [stages, setStages] = useState([]);

  const { toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [fetchingProject, setFetchingProject] = useState(false);

  useEffect(() => {
    if (id) {
      setStages([]);
      setFetchingProject(true);
      const sessionID = Cookies.get('sessionID');
      fetchProject(sessionID, id).then((data) => {
        if (data != null) {
          setName(data['ProjectName']);
          setFinancialProject(data['FinancialProject'] || '');
          setStartDate(dayjs(data['StartDate']));
          setEndDate(data['DueDate'] ? dayjs(data['DueDate']) : null);
          setDuration(dayjs(data['DueDate']).diff(dayjs(data['StartDate']), 'days'));
          setProjectType(types.find((t) => t.id === data['ProjectType']) || '');
          setStatus(statuses.find((status) => status.id === data['ProjectStatus']) || '');
          setStages(data['PM_StagesCollection']?.map((stage) => {
            stage['id'] = stage['StageID'];
            stage['task'] = activities.find((activity) => activity.id === stage['Task']) || null;
            stage['type'] = stageTypes.find((type) => type.id === stage['StageType']) || null;
            stage['start'] = stage['StartDate'] ? dayjs(stage['StartDate']) : null;
            stage['end'] = stage['EndDate'] ? dayjs(stage['EndDate']) : null;
            stage['cost'] = stage['ExpectedCosts'];
            return stage;
          }) || []);
        }
      }).finally(() => setFetchingProject(false));
    }
  }, [id, open]);

  const createDefaultStages = (projectType) => {
    const defaultStages = [];
    if (projectType) {
      console.log(activities);
      activities.forEach((activity, index) => {
        if (activitiesPerType[projectType.id].includes(activity.id)) {
          defaultStages.push({
            StageID: index + 1,
            StageType: 3,
            Task: activity.id,
            Description: activity.label,
            StartDate: startDate.format('YYYY-MM-DD'),
            UniqueID: String(index + 1).concat(' - ', activity.label),
            id: index + 1,
            task: activity,
            type: stageTypes.find((type) => type.id === 3),
            start: startDate,
            end: null,
            cost: 0,
          });
        }
      });
    }
    return defaultStages;
  }

  const handleChangeName = (e) => {
    setName(e.target.value);
  }

  const handleChangeFinancialProject = (e) => {
    setFinancialProject(e.target.value);
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  }

  const handleChangeDuration = (e) => {
    setDuration(e.target.value);
    setEndDate(startDate.add(e.target.value, 'days'));
  } 

  const handleChangeProjectType = (e, value) => {
    e.preventDefault();
    setProjectType(value);
    setStages(createDefaultStages(value));
  }

  const handleChangeStatus = (e, value) => {
    e.preventDefault();
    setStatus(value);
  }

  const handleClose = () => {
    if (id !== null) {
      handleReset();
    }
    onClose();
  }

  const handleReset = () => {
    setName('');
    setFinancialProject('');
    setStartDate(dayjs());
    setEndDate(null);
    setProjectType(null);
    setStatus(null);
    setStages([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onReset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id === null) {
      const data = {
        ProjectName: name,
        FinancialProject: financialProject,
        StartDate: startDate.format('YYYY-MM-DD'),
        DueDate: endDate?.format('YYYY-MM-DD'),
        ProjectType: projectType.id,
        ProjectStatus: status.id,
        PM_StagesCollection: stages.map((stage) => ({
          StageID: stage.id,
          StageType: stage.type.id,
          Task: stage.task.id,
          Description: stage.task.label,
          StartDate: stage.start.format('YYYY-MM-DD'),
          EndDate: stage.end?.format('YYYY-MM-DD'),
          ExpectedCosts: stage.cost,
          UniqueID: stage.UniqueID,
        })),
      };
      setSaving('Salvando');
      const sessionID = Cookies.get('sessionID');
      createProject(sessionID, data)
        .then((response) => {
          if (response) {
            handleReset();
            onSubmit();
            onClose();
          }
        })
        .catch((error) => {
          toggleStatus('Erro ao Salvar Projeto!', error, 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      const data = {
        StartDate: startDate.format('YYYY-MM-DD'),
        DueDate: endDate?.format('YYYY-MM-DD'),
      };
      setSaving('Atualizando');
      const sessionID = Cookies.get('sessionID');
      patchProject(sessionID, id, data)
        .then((response) => {
          if (response) {
            handleReset();
            onSubmit();
            onClose();
          }
        })
        .catch((error) => {
          toggleStatus('Erro ao Atualizar Projeto!', error, 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  }

  const handleDelete = (e) => {
    e.preventDefault();
  }

  const isInvalid = () => (
    startDate === null || (endDate && endDate <= startDate) || !startDate.isValid()
    || (endDate && !endDate.isValid()) || !name || !projectType || !status || !financialProject
    || stages.length === 0
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        component: 'form',
        onReset: handleCancel,
        onSubmit: handleSubmit,
        id: 'log-prod-form'
      }}
    >
      <DialogTitle>{id && 'Editar'} Projeto</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              disabled={id !== null}
              fullWidth
              inputProps={id !== null ? { shrink: 'true' } : {}}
              label="Nome"
              onChange={handleChangeName}
              required
              value={name}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              disabled={id !== null}
              fullWidth
              inputProps={id !== null ? { shrink: 'true' } : {}}
              label="Projeto Financeiro"
              onChange={handleChangeFinancialProject}
              required
              value={financialProject}
            />
          </Grid>
          <Grid item xs={0} sm={4} />
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  id="startDate"
                  label="Data Início"
                  onChange={handleChangeStartDate}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  value={startDate}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <TextField
                id="duration"
                label="Duração em dias"
                onChange={handleChangeDuration}
                required
                type="number"
                value={duration}
              />
              <FormHelperText>Data de Fim: { endDate?.format('DD/MM/YYYY') }</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disabled={id !== null}
              fullWidth
              options={types}
              onChange={handleChangeProjectType}
              renderInput={(params) => <TextField {...params} label="Tipo" required />}
              value={projectType}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disabled={id !== null}
              fullWidth
              options={statuses}
              onChange={handleChangeStatus}
              renderInput={(params) => <TextField {...params} label="Status" required />}
              value={status}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <StagesTable canAdd={projectType !== null} stages={stages} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          disabled
          onClick={handleDelete}
          variant="contained"
          size="large"
          sx={id == null ? { display: 'none' } : {}}
        >Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
        <Button
          disabled={isInvalid()}
          color="warning"
          variant="contained"
          size="large"
          type="submit"
        >Salvar</Button>
      </DialogActions>
      <Backdrop open={saving} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingProject} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

ProjectFormModal.defaultProps = {
  open: false,
  onClose: () => { },
  id: null,
};

export default ProjectFormModal;

