import dayjs from "dayjs";

export const statuses = [
  { simple: 'R', complex: 'boposReleased', label: 'Liberada' },
  { simple: 'L', complex: 'boposClosed', label: 'Fechada' },
  { simple: 'P', complex: 'boposPlanned', label: 'Planejada' },
  { simple: 'C', complex: 'boposCanceled', label: 'Cancelada' },
];

export async function fetchProjects(sessionID) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/QueryService_PostQuery");
  const filters = [
    "ProductionOrders/ProductionOrderStatus eq 'R'",
    "Projects/Code eq ProductionOrders/Project",
  ]
  const group = [
    "Projects/Code",
    "Projects/Name",
  ]
  const body = {
    "QueryPath": "$crossjoin(Projects, ProductionOrders)",
    "QueryOption": `$apply=filter(${filters.join(' and ')})/groupby((${group.join(' , ')}))`
  };

  let data = [];
  let repeat = true;
  let read = 0;

  while (repeat) {
    repeat = false;
    body.QueryOption = body.QueryOption.concat(`&$skip=${read}`);
    await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            const line = item['Projects'];
            line['id'] = line['Code'];
            line['label'] = line['Name'];
            data.push(line);
          });
          read += json.value.length;
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }

  return data;
}

export async function fetchOpenProductionOrders(sessionID, projectID = null, status = []) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders");
  let data = [];
  let repeat = true;
  let read = 0;

  const select = [
    'AbsoluteEntry',
    'ProductionOrderStatus',
    'ProductDescription',
    'ProductionOrderType',
    'CreationDate',
    'StartDate',
    'ItemNo',
    'Project',
    'DocumentNumber',
  ];
  apiUrl.searchParams.append('$select', select.join(','));

  const filter = [];

  if (status.length > 0) {
    const f = [];
    status.forEach((item) => {
      f.push(`ProductionOrderStatus eq '${item}'`);
    });
    filter.push(`(${f.join(' or ')})`);
  }

  if (projectID) {
    filter.push(`Project eq '${projectID}'`);
  }
  if (filter.length > 0) {
    apiUrl.searchParams.append('$filter', filter.join(' and '));
  }

  const order = [
    'CreationDate desc',
  ];
  apiUrl.searchParams.append('$orderby', order.join(','));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', read);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            item['id'] = item['AbsoluteEntry'];
            item['label'] = String(item['ItemNo']).concat(' - ', item['ProductDescription'], ' - ', item['DocumentNumber']);
            data.push(item);
          });
          read += json.value.length;
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

export async function fetchWarehouses(sessionID, productionOrderAbsoluteEntry) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/QueryService_PostQuery");
  const filters = [
    'Warehouses/WarehouseCode eq ProductionOrders/ProductionOrderLines/Warehouse',
    'ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry eq '.concat(productionOrderAbsoluteEntry),
  ]
  const group = [
    'Warehouses/WarehouseCode',
    'Warehouses/WarehouseName',
  ]
  const body = {
    "QueryPath": "$crossjoin(Warehouses, ProductionOrders/ProductionOrderLines)",
    "QueryOption": `$apply=filter(${filters.join(' and ')})/groupby((${group.join(' , ')}))`
  };

  let data = [];
  let repeat = true;
  let read = 0;

  while (repeat) {
    repeat = false;
    body.QueryOption = body.QueryOption.concat(`&$skip=${read}`);
    await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            const line = item['Warehouses'];
            line['id'] = line['WarehouseCode'];
            line['label'] = line['WarehouseCode'].concat(' - ', line['WarehouseName']);
            data.push(line);
          });
          read += json.value.length;
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }

  return data;
}

export async function fetchProductionOrderLines(sessionID, productionOrderAbsoluteEntry, warehouseCode) {
  const crossjoin = [
    'ProductionOrders',
    'ProductionOrders/ProductionOrderLines',
    'Items',
    'Items/ItemWarehouseInfoCollection',
  ];
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin('.concat(crossjoin.join(','), ')'));
  const data = [];
  let repeat = true;

  const selectPO = [
    'Project',
  ];

  const selectPOL = [
    'DocumentAbsoluteEntry',
    'LineNumber',
    'ItemNo',
    'ItemName',
    'ItemType',
    'Warehouse',
    'PlannedQuantity',
    'IssuedQuantity',
  ];

  const selectItem = [
    'ItemCode',
    'WarehouseCode',
    'InStock',
  ];

  const expand = [
    'ProductionOrders($select='.concat(selectPO.join(','), ')'),
    'ProductionOrders/ProductionOrderLines($select='.concat(selectPOL.join(','), ')'),
    'Items/ItemWarehouseInfoCollection($select='.concat(selectItem.join(','), ')'),
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filter = [
    'ProductionOrders/AbsoluteEntry eq ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry',
    'Items/ItemCode eq ProductionOrders/ProductionOrderLines/ItemNo',
    'ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry eq '.concat(productionOrderAbsoluteEntry),
    "ProductionOrders/ProductionOrderLines/Warehouse eq '".concat(warehouseCode, "'"),
    'ProductionOrders/ProductionOrderLines/ItemNo eq Items/ItemWarehouseInfoCollection/ItemCode',
    'ProductionOrders/ProductionOrderLines/Warehouse eq Items/ItemWarehouseInfoCollection/WarehouseCode',
    "ProductionOrders/ProductionOrderLines/ItemType eq '4'",
  ];
  apiUrl.searchParams.append('$filter', filter.join(' and '));

  const order = [
    'ProductionOrders/ProductionOrderLines/LineNumber asc',
  ];
  apiUrl.searchParams.append('$orderby', order.join(','));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(async (json) => {
        if (json.value.length > 0) {
          for (const item of json.value) {
            const line = item['ProductionOrders/ProductionOrderLines'];
            line['id'] = line['LineNumber'];
            line['label'] = String(line['ItemNo']).concat(' - ', line['ItemName']);
            const planned = parseFloat(line['PlannedQuantity']);
            line['planned'] = planned;
            const issued = parseFloat(line['IssuedQuantity']);
            line['issued'] = issued;
            const ware = item['Items/ItemWarehouseInfoCollection'];
            line['qtd'] = 0;
            const left = planned - issued;
            line['batch'] = {
              'SI': 0,
            };
            let stock = parseFloat(ware['InStock']);
            if (stock > 0) {
              const batch = await fetchItemBatch(sessionID, line['ItemNo'], warehouseCode, item['ProductionOrders']['Project']);
              if (batch) {
                line['batch'] = batch;
              }
            }
            stock = Object.values(line['batch']).reduce((a, b) => a + b, 0);
            const res = stock - left;
            if (res > 0) {
              line['qtd'] = left;
            } else {
              line['qtd'] = stock;
            }
            line['available'] = stock;
            data.push(line);
          }
          repeat = true;
        }
      }
      )
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

async function fetchItemBatch(sessionID, itemCode, warehouseCode, Project) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('sql02')/List");
  let data = null;

  apiUrl.searchParams.set('itemCode', "'".concat(itemCode, "'"));
  apiUrl.searchParams.set('whsCode', "'".concat(warehouseCode, "'"));
  apiUrl.searchParams.set('project', "'".concat(Project, "'"));

  await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`
    },
  })
    .then(response => response.json())
    .then(json => {
      if (json.value.length > 0) {
        data = {};
        json.value.forEach((item) => {
          const total = parseFloat(item['Quantity']) - parseFloat(item['IsCommited']);
          data[item['BatchNum']] = total > 0 ? total : 0;
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });

  return data;
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

export async function fetchInvGenExits(sessionID, startDate, endDate, employeeID = null, projectID = null, status = [], productionOrderID = null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(InventoryGenExits, InventoryGenExits/DocumentLines, ProductionOrders, Projects, ProductionOrders/ProductionOrderLines, EmployeesInfo)');
  const data = [];
  let repeat = true;

  const expand = [
    'InventoryGenExits($select=DocEntry, DocNum, DocDate)',
    'InventoryGenExits/DocumentLines($select=DocEntry, LineNum, BaseEntry, BaseLine, Quantity, U_EASY_Colab, U_EASY_HoraIni, U_U_EASY_HoraFim)',
    'ProductionOrders($select=AbsoluteEntry, DocumentNumber, ProductDescription, ItemNo, Project, ProductionOrderStatus)',
    'Projects($select=Code, Name)',
    'ProductionOrders/ProductionOrderLines($select=DocumentAbsoluteEntry, LineNumber, ItemNo, ItemName)',
    'EmployeesInfo($select=EmployeeID, FirstName, LastName)'
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filter = [
    'InventoryGenExits/DocEntry eq InventoryGenExits/DocumentLines/DocEntry',
    'InventoryGenExits/DocumentLines/BaseEntry eq ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry',
    'InventoryGenExits/DocumentLines/BaseLine eq ProductionOrders/ProductionOrderLines/LineNumber',
    'ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry eq ProductionOrders/AbsoluteEntry',
    'ProductionOrders/Project eq Projects/Code',
    'InventoryGenExits/DocumentLines/U_EASY_Colab eq EmployeesInfo/EmployeeID',
    "ProductionOrders/ProductionOrderLines/ItemType eq '4'",
    "InventoryGenExits/DocDate ge '".concat(startDate, "'"),
    "InventoryGenExits/DocDate le '".concat(endDate, "'"),
  ];
  if (employeeID) {
    filter.push(`InventoryGenExits/DocumentLines/U_EASY_Colab eq ${employeeID}`);
  }
  if (projectID) {
    filter.push(`ProductionOrders/Project eq '${projectID}'`);
  }
  if (status.length > 0) {
    const f = [];
    status.forEach((item) => {
      f.push(`ProductionOrders/ProductionOrderStatus eq '${item}'`);
    });
    filter.push(`(${f.join(' or ')})`);
  }
  if (productionOrderID) {
    filter.push(`InventoryGenExits/DocumentLines/BaseEntry eq ${productionOrderID}`);
  }
  apiUrl.searchParams.append('$filter', filter.join(' and '));

  const order = [
    'InventoryGenExits/DocDate desc',
    'InventoryGenExits/DocEntry desc',
    'InventoryGenExits/DocumentLines/LineNum asc'
  ];
  apiUrl.searchParams.append('$orderby', order.join(','));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            item['id'] = item['InventoryGenExits']['DocEntry'];
            const op = item['ProductionOrders'];
            item['op'] = String(op['ItemNo']).concat(' - ', op['ProductDescription'], ' - ', op['DocumentNumber']);
            const pr = item['Projects'];
            item['pr'] = pr['Name'];
            const serv = item['ProductionOrders/ProductionOrderLines'];
            item['srv'] = String(serv['ItemNo']).concat(' - ', serv['ItemName']);
            const ap = item['InventoryGenExits/DocumentLines'];
            item['date'] = dayjs(item['InventoryGenExits']['DocDate']).format('DD/MM/YYYY');
            item['qtd'] = String((ap['Quantity'] || 0).toFixed(2)).replace('.', ',');
            const emp = item['EmployeesInfo'];
            item['emp'] = `${emp['EmployeeID']} - ${emp['FirstName']} ${emp['LastName']}`;
            data.push(item)
          });
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}


export async function createInventoryExit(
  sessionID,
  { EmployeeID, ProductionOrder, ProductionOrderLines, Date }
) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryGenExits');

  ProductionOrderLines.forEach((line) => {
    let qtd = line['qtd'];
    const batches = Object.entries(line['batch']).map(([key, value]) => {
      if (value > qtd) {
        value = qtd;
      } else if (qtd > 0) {
        qtd -= value;
      } else {
        value = 0;
      }
      return {
        BatchNumber: key,
        Quantity: value,
      };
    });
    line['BatchNumbers'] = batches;
  });

  const data = [];
  ProductionOrderLines.forEach((line) => {
    if (line['qtd'] > 0) {
      data.push({
        Quantity: line['qtd'],
        ItemType: line['ItemType'],
        WarehouseCode: line['Warehouse'],
        BaseType: ProductionOrder['ProductionOrderType'],
        BaseEntry: ProductionOrder['AbsoluteEntry'],
        BaseLine: line['LineNumber'],
        SerialNumbers: [],
        BatchNumbers: line['BatchNumbers'],
        U_EASY_Colab: EmployeeID,
      });
    }
  });

  const body = {
    BPL_IDAssignedToInvoice: 3,
    DocDate: Date,
    DocumentLines: data,
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${sessionID}`
    },
    body: JSON.stringify(body)
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
}

