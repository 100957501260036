import dayjs from "dayjs";

import { fetchAcitvities } from '../TimeSheet/fetchData';

export const statuses = [
  { id: 'pst_Started', label: 'Iniciado' },
  { id: 'pst_Canceled', label: 'Cancelado' },
  { id: 'pst_Finalized', label: 'Finalizado' },
];

export const types = [
  { id: 'pt_Internal', label: 'Interno' },
  { id: 'pt_External', label: 'Externo' },
];

export const stageTypes = [
  { id: 1, label: 'Conceito/Iniciação' },
  { id: 2, label: 'Definição/Planejamento' },
  { id: 3, label: 'Apontamentos' },
  { id: 4, label: 'Desempenho e Controle' },
  { id: 5, label: 'Etapa de Finalização' },
  { id: 6, label: 'Compras' },
];

export const activitiesPerType = {
  pt_Internal: [2, 3, 6, 16, 19, 20, 22, 25, 27, 28, 30, 31],
  pt_External: [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 26, 29, 31],
}

export async function fetchProjects(sessionID, name, FinancialProject, status, type, startDate, endDate) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'AbsEntry,FinancialProject,ProjectName,StartDate,DueDate,ProjectStatus,ProjectType');
  apiUrl.searchParams.append('$orderby', 'AbsEntry desc');

  const filters = [];
  if (name) {
    filters.push(`contains(ProjectName,'${name}')`);
  }
  if (FinancialProject) {
    filters.push(`contains(FinancialProject,'${FinancialProject}')`);
  }
  if (status) {
    filters.push(`ProjectStatus eq '${status}'`);
  }
  if (type) {
    filters.push(`ProjectType eq '${type}'`);
  }
  if (startDate) {
    filters.push(`StartDate ge '${startDate}'`);
  }
  if (endDate) {
    filters.push(`StartDate le '${endDate}'`);
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500',
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['AbsEntry'];
            item['label'] = item['ProjectName'];
            item['pf'] = item['FinancialProject'];
            item['di'] = dayjs(item['StartDate']).format('DD/MM/YYYY');
            item['df'] = item['DueDate'] ? dayjs(item['DueDate']).format('DD/MM/YYYY') : '-';
            item['type'] = item['ProjectType'] ? types.find(type => type.id === item['ProjectType']).label : '-';
            item['status'] = item['ProjectStatus'] ? statuses.find(status => status.id === item['ProjectStatus']).label : '-';
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

export async function fetchProject(sessionID, ProjectID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${ProjectID})`);

  await fetchAcitvities(sessionID);

  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`
    },
  })
    .then(response => response.json())
    .then(json => {
      return json;
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500',
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

export async function createProject(sessionID, data) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
}

export async function patchProject(sessionID, projectID, data) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements'.concat('(', projectID, ')'));

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 204) {
    throw await response.json();
  }
  return;
}