import dayjs from "dayjs";
import { activities } from "layouts/TimeSheet/fetchData";

export async function fetchApontamentos(sessionID, employeeID, financialProject, startDate, endDate) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagementTimeSheet');

  // apiUrl.searchParams.append('$top', 10);

  const select = [
    'DocNumber',
    'DateFrom',
    'DateTo',
    'FirstName',
    'LastName',
    'PM_TimeSheetLineDataCollection'
  ];

  // #### Nested query não quer funcionar :/
  /*const subselect = [
    'ActivityType',
    'Date',
    'StartTime',
    'Break',
    'EndTime',
    'EffectiveTime',
    'FinancialProject',
    'U_EASY_Obs',
    'U_EASY_Projeto',
  ]
  select.push(
    'PM_TimeSheetLineDataCollection ($select='.concat(
      subselect.join(',').concat(')')
    )
  )*/

  apiUrl.searchParams.append('$select', select.join(','));

  const filter = [
    'DateFrom ge '.concat(startDate),
    'DateTo le '.concat(endDate),
    'UserID eq '.concat(employeeID),
  ];
  apiUrl.searchParams.append('$filter', filter.join(' and '));

  const order = [
    'DateFrom asc',
    'DocNumber asc'
  ];
  apiUrl.searchParams.append('$orderby', order.join(', '));

  const data = [];

  await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then(json => json.value.map((item) => {
      const sub = item['PM_TimeSheetLineDataCollection'][0];
      if (sub['FinancialProject'] == financialProject) {
        Object.keys(sub).forEach((key) => item[key] = sub[key]);
        delete item['PM_TimeSheetLineDataCollection'];
        item['id'] = item['DocNumber'];
        item['Date'] = dayjs(item['Date']);
        item['Activity'] = activities.find((activity) => activity.id === item['ActivityType'])?.label;
        item['StartTime'] = item['StartTime'].length === 8 ? item['StartTime'].slice(0, 5) : item['StartTime'];
        item['Break'] = item['Break'].length === 8 ? item['Break'].slice(0, 5) : item['Break'];
        item['EndTime'] = item['EndTime'].length === 8 ? item['EndTime'].slice(0, 5) : item['EndTime'];
        item['EffectiveTime'] = item['EffectiveTime'].length === 8 ? item['EffectiveTime'].slice(0, 5) : item['EffectiveTime'];
        data.push(item);
      }
    }))
    .catch(error => {
      console.error('Error:', error);
      return null;
    });
    return data;
}

export async function fetchColaboradores(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);
  
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

export async function fetchProjetos(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'ProjectName,FinancialProject');
  apiUrl.searchParams.append('$filter', "ProjectStatus ne 'pst_Canceled'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);
  
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = item['ProjectName']
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}
