import React, { memo, useCallback } from 'react';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';

import { useStatus } from '../../hooks/useStatus';

const ProjectsTable = memo(function ProjetosTable({ data, isLoading, isManager, onEdit }) {

  const { toggleStatus } = useStatus();

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id && isManager) {
      onEdit(id);
    } else {
      toggleStatus('Atenção', 'Funcionalidade limitada por política organizacional', 'warning');
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Projeto Financeiro</TableCell>
            <TableCell>Data Início</TableCell>
            <TableCell>Data Fim</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Edit /></IconButton></TableCell>
              <TableCell component="th" scope="row">
                {item.id}
              </TableCell>
              <TableCell>{item.label}</TableCell>
              <TableCell>{item.pf}</TableCell>
              <TableCell>{item.di}</TableCell>
              <TableCell>{item.df}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item.status}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Nenhum projeto encontrado
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ProjectsTable;
