import { useContext } from 'react';
import { StatusContext } from '../context/status';

export const useStatus = () => {
  const { status, setStatus } = useContext(StatusContext);

  /**
   * Toggles the display of the Status Modal
   *
   * @param {string} [title=''] - The title to set in the status.
   * @param {(string|Object)} [message=''] - The message to set in the status. Can be a string or an object.
   * @param {('info'|'warning'|'error'|'success')} [type='info'] - The type of the status. This can be 'info', 'warning', 'error', or 'success'.
   * @param {CallableFunction} [callback] - A callback function to be executed after the status is toggled. 
   */
  function toggleStatus(title = '', message = '', type = 'info', callback = null) {
    setStatus({ isOpen: !status.isOpen, title, message, type, callback });
  }

  return { status, toggleStatus };
};