import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete, Backdrop, Box, Button, Card, CircularProgress, FormControl,
  Grid, Icon, InputLabel, TextField, Typography, createTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import TimeSheetModal from './form';
import TimeSheetTable from './table';
import { fetchEmployees, fetchTimeSheetsWithTotal, fetchProjects } from './fetchData';
import { exportApontamentosToXLSX, importFromTemplate, exportTemplate } from './exportData';
import RelatorioForm from 'components/RelatorioForm';
import { useStatus } from '../../hooks/useStatus';
import dynTheme from 'assets/dynTheme';

function TimeSheetView() {

  const [refresh, setRefresh] = useState(false);

  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showRelatorio, setShowRelatorio] = useState(false);

  const fileRef = useRef();

  const isManager = Cookies.get('logemployeerole') === 'Manager';
  const isApontaEmLote = Cookies.get('logemployeelote') === 'Y' || isManager;

  const [employee, setEmployee] = useState(null);
  const [project, setProject] = useState(null);
  const [startDate, setStartDate] = useState(isManager ? dayjs() : dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs());

  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [fetchingEmployees, setFetchingEmployees] = useState(false);
  const [fetchingProjects, setFetchingProjects] = useState(false);

  const [timesheets, setTimesheets] = useState([]);
  const [timeTotal, setTimeTotal] = useState(0);
  const [timeQuantity, setTimeQuantity] = useState(0);
  const [fetchingTimesheets, setFetchingTimesheets] = useState(false);

  const { toggleStatus } = useStatus();

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    if (isManager) {
      setFetchingEmployees(true);
      fetchEmployees(sessionID).then((data) => {
        if (data != null) {
          setEmployees(data);
        }
      }).finally(() => setFetchingEmployees(false));
    } else {
      setEmployee({
        EmployeeID: Cookies.get('logemployeeID'),
        FirstName: Cookies.get('logemployeefirstname'),
        LastName: Cookies.get('logemployeelastname'),
        label: `${Cookies.get('logemployeeID')} - ${Cookies.get('logemployeefirstname')} ${Cookies.get('logemployeelastname')}`,
      })
    }
    setFetchingProjects(true);
    fetchProjects(sessionID).then((data) => {
      if (data != null) {
        setProjects(data);
      }
    }).finally(() => setFetchingProjects(false));
  }, [isManager]);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    if (isManager || employee !== null) {
      setFetchingTimesheets(true);
      fetchTimeSheetsWithTotal(
        sessionID,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        employee?.EmployeeID,
        project?.FinancialProject,
      ).then((data) => {
        if (data != null) {
          setTimesheets(data.timesheets);
          setTimeTotal(data.total);
          setTimeQuantity(data.quantity);
        }
      }).finally(() => {
        setFetchingTimesheets(false);
        setRefresh(false);
      });
    }
  }, [employee, project, startDate, endDate, refresh, isManager]);

  const handleOpenAddForm = (e) => {
    e.preventDefault();
    setShowForm(true);
  }

  const handleEditForm = (id) => {
    setEditId(id);
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setEditId(null);
  }

  const handleSubmitForm = () => {
    handleCloseForm();
    setRefresh(true);
  }

  const handleChangeEmployee = (e, value) => {
    e.preventDefault();
    setEmployee(value);
  }

  const handleChangeProject = (e, value) => {
    e.preventDefault();
    setProject(value);
  }

  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  const handleExportRows = (e) => {
    e.preventDefault();
    exportApontamentosToXLSX(timesheets);
  }

  const handleImportFile = async (e) => {
    if (isApontaEmLote) {
      setFetchingProjects(true);
      try {
        const file = e.target.files[0];
        const result = await importFromTemplate(file);
        toggleStatus('Importação de Apontamentos', result, 'info', () => setRefresh(true));
      } catch (e) {
        toggleStatus('Erro', e.message, 'error');
      } finally {
        setFetchingProjects(false);
      }
    } else {
      toggleStatus('Atenção', 'Funcionalidade limitada por política organizacional', 'warning');
    }
  }

  const handleExportTemplate = async (e) => {
    exportTemplate(projects);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Apontamentos de Projeto
              </MDTypography>
            </Box>
            <Grid container spacing={2} style={{ width: 'auto' }}>
              <Grid item xs={12} md={4}>
                <MDButton fullWidth variant="gradient" color="warning" onClick={handleOpenAddForm}>
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>add</Icon>
                  Novo
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton
                  fullWidth variant="gradient" onClick={handleImportFile}
                  color="warning" component={isApontaEmLote ? "label" : undefined}
                >
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>upload</Icon>
                  Importar
                  <input
                    type="file"
                    accept=".xlsx"
                    hidden
                    multiple={false}
                    onChange={handleImportFile}
                    ref={fileRef}
                  />
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton onClick={handleExportTemplate} fullWidth variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>tablechart</Icon>
                  Template
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disabled={!isManager}
                      fullWidth
                      loadingText="Carregando..."
                      onChange={handleChangeEmployee}
                      options={employees}
                      renderInput={(params) => <TextField {...params} label="Colaborador" />}
                      value={employee}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      fullWidth
                      loadingText="Carregando..."
                      onChange={handleChangeProject}
                      options={projects}
                      renderInput={(params) => <TextField {...params} label="Projeto" />}
                      value={project}
                    />
                  </Grid>
                  <Grid item xs={0} md={4} />
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-inicio" label="Data Inicial" onChange={handleChangeStartDate} value={startDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0} md={2} />
                  <Grid item xs={12} md={2}>
                    <Button
                      disabled={fetchingEmployees || fetchingProjects}
                      fullWidth color="warning" size="large" variant="outlined"
                      onClick={() => setShowRelatorio(true)}
                    >
                      <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>download</Icon>
                      Relatório
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Button
                      disabled={timesheets.length === 0}
                      fullWidth color="warning" size="large" variant="outlined"
                      onClick={handleExportRows}
                    >
                      <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>download</Icon>
                      Exportar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Typography variant="h6" color="InactiveCaptionText">
                    Total de Horas: {String(parseFloat(timeTotal / 60).toFixed(2)).replace('.', ',')} - {timeQuantity} registros
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <TimeSheetTable
                  data={timesheets}
                  isLoading={fetchingTimesheets}
                  onEdit={handleEditForm}
                  employees={employees}
                  projects={projects}
                />
              </Grid>
            </Grid>
            <TimeSheetModal
              open={showForm}
              onClose={handleCloseForm}
              onSubmit={handleSubmitForm}
              employees={employees}
              fixedEmployee={isManager ? null : employee}
              projects={projects}
              id={editId}
            />
            <RelatorioForm
              isOpen={showRelatorio}
              isManager={isManager}
              onClose={() => setShowRelatorio(false)}
              colaboradores={employees}
              projetos={projects}
            />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={fetchingEmployees || fetchingProjects}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default TimeSheetView;
