import React, { Component } from 'react';
//import React from 'react';
import $ from 'jquery';
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import {  TextField} from '@mui/material';
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";





class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PurchaseOrderData: [],
      projectFilter: '',
      currentPage: 1,
      isLoading: false,
      showUpdateForm: false,
      selectedDocEntry: null,
      showImportProgressPopup: false,
      selectedLineNum: null,
      updatedField1: '', // Initialize as an empty string
      updatedField2: '',
      updatedField3: '',
      updatedField4: '',
      failedDocEntries: [],
        // ... other state variables
        importProgress: {
          totalRows: 0, // Total number of rows to import
          rowsProcessed: 0, // Number of rows processed
          failedRequests: 0, // Number of failed requests
        },
    
    };
  }


  openImportProgressPopup = () => {
    this.setState({ showImportProgressModal: true });
    console.log('Opening import progress modal');
  };
  
  closeImportProgressPopup = () => {
    this.setState({ showImportProgressModal: false });
    console.log('Closing import progress modal');
  };
  
  componentDidMount() {
    // Set app element for react-modal (must be done once)
    Modal.setAppElement('#app'); // Replace '#root' with the appropriate element
  }

 
    handleDownloadClick = () => {
      // Use the public URL for your template file
      const templateUrl = process.env.PUBLIC_URL + '/importfile.xlsx';
      const link = document.createElement('a');
      link.href = templateUrl;
      link.download = 'importfile.xlsx'; // Specify the filename for the downloaded file.
      link.click();
    };

  handleImportButtonClick = (e) => {
    // Trigger the hidden file input element
    this.fileInput.click();
  };



handleFileInputChange = (e) => {
  const file = e.target.files[0];

  if (!file) {
    return;
  }

  e.target.value = '';

  // Use XLSX library to parse the XLSX file
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });

    // Assume the sheet name is 'Sheet1'
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];

    // Extract data from the sheet
    const rows = XLSX.utils.sheet_to_json(sheet);

    // Group rows by DocEntry
    const groupedRows = {};

    //count
    const uniqueDocEntries = new Set(rows.map((row) => row.DocEntry));

    rows.forEach((row) => {
      const { DocEntry } = row;

      if (!groupedRows[DocEntry]) {
        groupedRows[DocEntry] = [];
      }

      groupedRows[DocEntry].push({
        LineNum: row.LineNum,
        U_TextoLivre2: row.U_TextoLivre2,
        FreeText: row.FreeText,
        U_Grey_DtFat: row.U_Grey_DtFat,
        ShipDate: row.ShipDate,
        // Add other fields here
      });
    });

    this.setState(
        (prevState) => ({
          importProgress: {
            totalRows: uniqueDocEntries.size,
            rowsProcessed: 0,
            failedRequests: 0,
          },
        }),
        () => {
          // Iterate through grouped rows and send PATCH requests
          Object.keys(groupedRows).forEach((DocEntry) => {
            const documentLines = groupedRows[DocEntry];

            // Construct the PATCH request and send it
            this.sendPatchRequest(DocEntry, documentLines);
          });
        }
      );

  };
  this.openImportProgressPopup();
  reader.readAsArrayBuffer(file);
};

sendPatchRequest = (DocEntry, documentLines) => {
  const { sessionId } = this.props;

  // Construct the patchData object with the necessary fields
  const patchData = {
    DocumentLines: documentLines,
  };

  $.ajax({
    url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseOrders(${DocEntry})`,
    type: 'PATCH',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    xhrFields: {
      withCredentials: true,
    },
    data: JSON.stringify(patchData),
    dataType: 'json',
    contentType: 'application/json',
    success: (response) => {
      console.log(`Updated DocEntry: ${DocEntry}`);
      console.log('Request Body:', JSON.stringify(patchData, null, 2));
      // You can handle the success response here
      this.updateImportProgress(true);
    },
    error: (error) => {
      console.error(`Error updating DocEntry: ${DocEntry}`, error);
      console.log('Request Body:', JSON.stringify(patchData, null, 2));
      // You can handle the error here
      this.updateImportProgress(false, DocEntry);
    },
  });
};

updateImportProgress = (isSuccessful, DocEntry) => {
  this.setState((prevState) => ({
    importProgress: {
      totalRows: prevState.importProgress.totalRows,
      rowsProcessed: prevState.importProgress.rowsProcessed + 1,
      failedRequests: isSuccessful
        ? prevState.importProgress.failedRequests
        : prevState.importProgress.failedRequests + 1,
    },
    failedDocEntries: isSuccessful
      ? prevState.failedDocEntries
      : [...prevState.failedDocEntries, DocEntry],
  }));
};



  exportDataToCSV = () => {
    const { PurchaseOrderData } = this.state;

    if (!PurchaseOrderData || !PurchaseOrderData.length) {
      alert('No data available to export.');
      return;
    }

    let csvContent = 'data:text/csv;charset=utf-8,';
    const headerRow = ['DocEntry', 'CardName', 'Project', 'ItemCode', 'ItemDescription', 'Quantity', 'ShipDate'];
    csvContent += headerRow.join(',') + '\n';

    PurchaseOrderData.forEach((item) => {
      item.DocumentLines.forEach((line) => {
        const rowData = [
          item.DocEntry,
          item.CardName,
          item.Project,
          line.ItemCode,
          line.ItemDescription,
          line.Quantity,
          line.ShipDate,
        ];
        csvContent += rowData.join(',') + '\n';
      });
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'purchase_order_data.csv');
    document.body.appendChild(link);
    link.click();
  };

  

  exportDataToXLSX = () => {
    const { PurchaseOrderData } = this.state;

  if (!PurchaseOrderData || !PurchaseOrderData.length) {
    alert('No data available to export.');
    return;
  }

  const header = ['DocEntry', 'CardName', 'Project', 'ItemCode', 'ItemDescription', 'Quantity', 'ShipDate'];
  const data = [];

  PurchaseOrderData.forEach((item) => {
    item.DocumentLines.forEach((line) => {
      data.push([
        item.DocEntry,
        item.CardName,
        item.Project,
        line.ItemCode,
        line.ItemDescription,
        line.Quantity,
        line.ShipDate,
      ]);
    });
  });

  const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'PurchaseOrderData');
  XLSX.writeFile(wb, 'purchase_order_data.xlsx');
};



  fetchData = () => {
    const { sessionId } = this.props;
    const { projectFilter, currentPage, isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });

    const headers = {
      Authorization: `Bearer ${sessionId}`,
    };

    const skip = (currentPage - 1) * 20; // Adjust the page size here (20 in this case)

    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseOrders/?$filter=Project eq '${projectFilter}'&$skip=${skip}&$orderby=DocEntry`;

    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',
      success: (response) => {
        const newData = response.value || [];

        this.setState((prevState) => ({
          PurchaseOrderData: [...prevState.PurchaseOrderData, ...newData],
          isLoading: false,
        }));

        if (newData.length === 20) {
          // If you received 20 records, there might be more, so increment the page
          this.setState(
            (prevState) => ({ currentPage: prevState.currentPage + 1 }),
            this.fetchData
          );
        }
      },
      error: (error) => {
        console.error('Error:', error);

        this.setState({ isLoading: false });
      },
    });
  };

  handleProjectInputChange = (e) => {
    this.setState({ projectFilter: e.target.value, PurchaseOrderData: [], currentPage: 1 }); // Clear previous data and reset page
  };


  handleRefreshButton = (e) => {
    this.setState({ PurchaseOrderData: [], currentPage: 1 }); // Clear previous data and reset page
    this.fetchData();
  };

  // Function to handle the "Update" button click and open the update form modal
  handleUpdateButtonClick = (item,lineNum) => {
    this.setState({
      showUpdateForm: true,
      selectedDocEntry: item.DocEntry,
      selectedLineNum: lineNum,
      updatedField1: item.Field1,
      updatedField2: item.Field2,
      updatedField3: item.Field3,
      updatedField4: item.Field4,
      // ... initialize other fields based on the selected item
    });
  };

  // Function to close the update form modal
  closeUpdateFormModal = () => {
    this.setState({
      showUpdateForm: false,
      selectedDocEntry: null,
      selectedLineNum: null,
    });
  };

  // Function to handle the "Submit" button click and send the PATCH request
  handleUpdateSubmit = () => {
    console.log('Submit button clicked');
    const { sessionId } = this.props;
    const {
      selectedDocEntry,
      //selectedLineNum,
      //updatedField1,
      // ... other updated fields
    } = this.state;

    const patchData = {
      DocumentLines: [
        {
          LineNum: this.state.selectedLineNum,
          U_TextoLivre2: this.state.updatedField1,
          FreeText:this.state.updatedField2,
          U_Grey_DtFat:this.state.updatedField3,
          ShipDate:this.state.updatedField4,
          // Add other fields here
        },
      ],
    };

    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseOrders(${selectedDocEntry})`,
      type: 'PATCH',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(patchData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Request Body:', JSON.stringify(patchData, null, 2));
        
        this.setState({ PurchaseOrderData: [], currentPage: 1 }, () => {
          this.fetchData();
        });
  
        this.setState({
          showUpdateForm: false,
          selectedDocEntry: null,
          selectedLineNum: null,
        });
      },
      error: (error) => {
        console.error('Error:', error);
        console.log('Request Body:', JSON.stringify(patchData, null, 2));
      },
    });
  };

  renderTable() {
    const { PurchaseOrderData, isLoading } = this.state;

    console.log('PurchaseOrderData:', PurchaseOrderData);

    if (isLoading) {
      return <p>Loading...</p>;
    }

    if (!PurchaseOrderData || !PurchaseOrderData.length) {
      return <p>No data available.</p>;
    }

    return (

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Ordens de Compra
                </MDTypography>
              </MDBox>            
              <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">              
                    <thead>    
                    <TableRow>
                    <TableCell align="center" style={{ width: '50px' }}><MDTypography variant="h6" >-</MDTypography></TableCell>          
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >DocEntry</MDTypography></TableCell>      
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >LineNum</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '200px' }}><MDTypography variant="h6" >CardName</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >Project</MDTypography></TableCell>        
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >ItemCode</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '200px' }}><MDTypography variant="h6" >ItemDescription</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '50px' }}><MDTypography variant="h6" >Qtd</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >U_Grey_DtFat</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >ShipDate</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >FreeText</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >U_TextoLivre2</MDTypography></TableCell>
                    </TableRow>
                    </thead>   
                      <tbody>
                    {PurchaseOrderData.map((item, parentIndex) => (
                        <React.Fragment key={parentIndex}>{
                        item.DocumentLines.map((line, lineIndex) => (
                        <TableRow key={`${parentIndex}_${lineIndex}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell align="center" style={{ width: '50px' }}><IconButton onClick={() => this.handleUpdateButtonClick(item,line.LineID)}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" ><Icon>edit</Icon></MDTypography></IconButton></TableCell>                        
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.DocEntry != null ? item.DocEntry : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.LineNum != null ? item.LineNum : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '200px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.CardName != null ? item.CardName : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.Project != null ? item.Project : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemCode != null ? line.ItemCode : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '200px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemDescription != null ? line.ItemDescription : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '50px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.Quantity != null ? line.Quantity : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.U_Grey_DtFat != null ? line.U_Grey_DtFat : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ShipDate != null ? line.ShipDate : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.FreeText != null ? line.FreeText : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.U_TextoLivre2 != null ? line.U_TextoLivre2 : '-'}</MDTypography></TableCell>
                        </TableRow>
                        ))}
                        </React.Fragment>
                     ))}
                  </tbody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  render() {
    const { importProgress, showImportProgressModal } = this.state;
    return (


      
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox py={3}>
        <Grid container spacing={3}>

        <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="Total de Pedidos"
                //count={totalEffectiveHours != null ? totalEffectiveHours : '-'}  
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Total de pedidos",
                }}
              />
            </MDBox>            
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>

            <MDBox  pt={2} >
            
           
                  <TextField
                    label="Projeto"
                    name="projectFilter"                    
                    value={this.state.projectFilter}
                    onChange={this.handleProjectInputChange}
                    fullWidth
                  />
            

                  </MDBox>   
                
                </MDBox>
          </Grid>


          </Grid>          
          </MDBox>

          <MDButton variant="gradient" color="warning" onClick={this.handleRefreshButton} >
            <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
            &nbsp;Carregar Pedidos
          </MDButton>
          <MDButton variant="gradient" color="warning" onClick={this.exportDataToCSV} >
            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
            &nbsp;Exportar CSV
          </MDButton>
          <MDButton variant="gradient" color="warning" onClick={this.exportDataToXLSX} >
            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
            &nbsp;Exportar XLSX
          </MDButton>
          <MDButton variant="gradient" color="warning" onClick={this.handleDownloadClick} >
            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
            &nbsp;Download Template
          </MDButton>

          

      
          
          <input
            type="file"
            accept=".xlsx"
            ref={(input) => (this.fileInput = input)}
            style={{ display: 'none' }}
            onChange={this.handleFileInputChange}
          />
          <MDButton variant="gradient" color="warning" onClick={this.handleImportButtonClick} >
            <Icon sx={{ fontWeight: "bold" }}>publish</Icon>
            &nbsp;Atualizar em massa
          </MDButton>
       





        


        {this.renderTable()}      

        <Modal
        isOpen={showImportProgressModal}
        onRequestClose={this.closeImportProgressPopup}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Import Progress: {importProgress.rowsProcessed} / {importProgress.totalRows}</h2>
        <div>
          Failed Requests: {importProgress.failedRequests}
        </div>
        
        {importProgress.failedRequests > 0 && (
          <div>
            Failed DocEntries: {this.state.failedDocEntries.join(', ')}
          </div>
        )}
        <button className="button" onClick={this.closeImportProgressPopup}>Close</button>
      </Modal>
  

        {/* ReactModal for Update Form */}
        <ReactModal
          isOpen={this.state.showUpdateForm}
          onRequestClose={this.closeUpdateFormModal}
          className="modal-content" // Add class for the modal content
          overlayClassName="modal-overlay" // Add class for the modal overlay
        >
          <div>
            {/* Modal content for updating data */}
            {/* Render input fields, buttons, and other form elements */}
            <h2>Atualizar Item do Pedido</h2>
            <label>U_TextoLivre2:</label>
            <input
              type="text"
              value={this.state.updatedField1 || ''} // Ensure it's not undefined
              onChange={(e) => this.setState({ updatedField1: e.target.value })}
              className="black-input"
            />
            <div>
            <label>FreeText:</label>
            <input
              type="text"
              value={this.state.updatedField2 || ''} // Ensure it's not undefined
              onChange={(e) => this.setState({ updatedField2: e.target.value })}
              className="black-input"
            />
            </div>
             <label>U_Grey_DtFat:</label>
            <input
              type="text"
              value={this.state.updatedField3 || ''} // Ensure it's not undefined
              onChange={(e) => this.setState({ updatedField3: e.target.value })}
              className="black-input"
            />
            <div>
             <label>ShipDate:</label>
            <input
              type="text"
              value={this.state.updatedField4 || ''} // Ensure it's not undefined
              onChange={(e) => this.setState({ updatedField4: e.target.value })}
              className="black-input"
            />
            </div>

            {/* ... other input fields */}
            <button className="button" onClick={this.handleUpdateSubmit}>Submit</button>
            <button className="button" onClick={this.closeUpdateFormModal}>Cancel</button>
          </div>
        </ReactModal>
      

      <Footer />
      </DashboardLayout>
    );
  }
}

export default PurchaseOrder;
