import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { activities, locals } from './fetchData';
import MonetaryInput from 'components/monetaryInput';

const StageSubForm = ({ line, onSubmit, onClose, onDelete, open }) => {

  const [id, setID] = useState("");
  const [stageType, setStageType] = useState(3);
  const [startDate, setStartDate] = useState(dayjs());
  const [closeDate, setCloseDate] = useState(null);
  const [finishedDate, setFinishedDate] = useState(null);
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [uniqueID, setUniqueID] = useState("");
  const [plannedTime, setPlannedTime] = useState(0);
  const [expectedCosts, setExpectedCosts] = useState(0);
  const [local, setLocal] = useState(null);

  const [shouldReset, setShouldReset] = useState(false);

  useEffect(() => {
    if (line) {
      setID(line['StageID']);
      setStageType(line['StageType']);
      setStartDate(dayjs(line['StartDate']));
      setCloseDate(line['CloseDate'] ? dayjs(line['CloseDate']) : null);
      setFinishedDate(line['FinishedDate'] ? dayjs(line['FinishedDate']) : null);
      setTask(line['Task'] ? activities.find(activity => activity.id === line['Task'])?.label : "");
      setDescription(line['Description']);
      setUniqueID(line['UniqueID']);
      setPlannedTime(line['U_EASY_TempoPlanejado']);
      setExpectedCosts(line['ExpectedCosts']);
      setLocal(line['U_Local']);
    } else if (shouldReset) {
      setShouldReset(false);
      handleReset();
    }
  }, [line]);

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const handleChangeCloseDate = (date) => {
    setCloseDate(date);
  }

  const handleChangeTask = (e, value) => {
    e.preventDefault();
    setTask(value);
    if (id) {
      setUniqueID(String(id).concat(' - ', value.label));
    } else {
      setUniqueID(value.label);
    }
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  }

  const handleChangePlannedTime = (e) => {
    setPlannedTime(e.target.value);
  }

  const handleChangeExpectedCosts = (e) => {
    setExpectedCosts(e.target.value);
  }

  const handleChangeLocal = (e, value) => {
    e.preventDefault();
    setLocal(value);
  }

  const handleReset = () => {
    setID("");
    setStageType(3);
    setStartDate(dayjs());
    setCloseDate(null);
    setFinishedDate(null);
    setTask("");
    setDescription("");
    setUniqueID("");
    setPlannedTime(0);
    setExpectedCosts(0);
    setLocal(null);
  }

  const handleClose = () => {
    handleReset();
    onClose();
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setShouldReset(true);
    onDelete();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShouldReset(true);
    const data = {
      ...line,
      StageID: id,
      StageType: stageType,
      StartDate: startDate.format('YYYY-MM-DD'),
      CloseDate: closeDate ? closeDate.format('YYYY-MM-DD') : null,
      FinishedDate: finishedDate ? finishedDate.format('YYYY-MM-DD') : null,
      Task: task.id,
      Description: description,
      UniqueID: uniqueID,
      U_EASY_TempoPlanejado: plannedTime,
      ExpectedCosts: expectedCosts,
      U_Local: local,
    };
    delete data['label'];
    onSubmit(data);
  }

  return (
    <Dialog
      open={open} onClose={handleClose}
      maxWidth="md" fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleReset,
        onSubmit: handleSubmit,
        id: 'project-stage-form'
      }}
    >
      <DialogTitle>{id ? 'Edição de' : 'Adicionar'} Estágio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item xs={12} md={5}>
            <Autocomplete
              fullWidth
              onChange={handleChangeTask}
              options={activities}
              renderInput={(params) => <TextField {...params} label="Etapa" required />}
              value={task}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              label="Descrição"
              value={description}
              onChange={handleChangeDescription}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tempo Planejado"
              value={plannedTime}
              onChange={handleChangePlannedTime}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              onChange={handleChangeLocal}
              options={locals}
              renderInput={(params) => <TextField {...params} label="Local" />}
              value={local}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  label="Data de Início"
                  value={startDate}
                  onChange={handleChangeStartDate}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  label="Data de Fim"
                  value={closeDate}
                  onChange={handleChangeCloseDate}
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  label="Data de Finalização"
                  value={finishedDate}
                  onChange={setFinishedDate}
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              label="Custos Previsto"
              value={expectedCosts}
              onChange={handleChangeExpectedCosts}
              InputProps={{
                inputComponent: MonetaryInput
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" sx={!id && { display: 'none' }} onClick={handleDelete} variant="contained">Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" onClick={handleClose}>Cancelar</Button>
        <Button color="warning" variant="contained" type="submit">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

StageSubForm.defaultProps = {
  line: null,
  onSubmit: () => { },
  open: false,
  onClose: () => { },
};

export default StageSubForm;
