import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Box, Card, FormControl,
  Grid, TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import InventoryTransferRequestDetailModal from './detail';
import InventoryTransferRequestTable from './table';
import { statuses, fetchInventoryTransferRequests } from './fetchData';
import dynTheme from 'assets/dynTheme';

let debounceTimer;
function debounce(func, delay) {
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

function InventoryTransferRequestView() {

  const [refresh, setRefresh] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const [docNumber, setDocNumber] = useState('');
  const [fromWarehouse, setFromWarehouse] = useState('');
  const [toWarehouse, setToWarehouse] = useState('');
  const [comments, setComments] = useState('');
  const [status, setStatus] = useState(statuses[0]);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'month').endOf('month'));

  const [requests, setRequests] = useState([]);
  const [fetchingProjects, setFetchingProjects] = useState(false);

  useEffect(debounce(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingProjects(true);
    fetchInventoryTransferRequests(
      sessionID,
      docNumber,
      status?.id,
      fromWarehouse,
      toWarehouse,
      comments,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
    ).then((data) => {
      if (data != null) {
        setRequests(data);
      }
    }).finally(() => {
      if (!debounceTimer) {
        setFetchingProjects(false);
      }
    });
  }, 1000), [docNumber, fromWarehouse, toWarehouse, comments, status, startDate, endDate, refresh]);

  const handleDetail = (id) => {
    setDetailId(id);
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setDetailId(null);
  }

  const handleSubmitForm = () => {
    setRefresh(!refresh);
  }

  const handleChangeDocNumber = (e) => {
    setDocNumber(e.target.value);
  }

  const handleChangeFromWarehouse = (e) => {
    setFromWarehouse(e.target.value);
  }

  const handleChangeToWarehouse = (e) => {
    setToWarehouse(e.target.value);
  }

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  }

  const handleChangeStatus = (e, value) => {
    e.preventDefault();
    setStatus(value);
  }
  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Solicitações de Picking
              </MDTypography>
            </Box>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="docNumber" label="Número da Picking"
                        value={docNumber} onChange={handleChangeDocNumber}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="fromWarehouse" label="De Depósito" value={fromWarehouse}
                        onChange={handleChangeFromWarehouse}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="toWarehouse" label="Para Depósito"
                        value={toWarehouse} onChange={handleChangeToWarehouse}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="comments" label="OP ou Projeto"
                        value={comments} onChange={handleChangeComments}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="status" options={statuses}
                        value={status} onChange={handleChangeStatus}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-inicio" label="Data Inicial" onChange={handleChangeStartDate} value={startDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0} md={1} />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <InventoryTransferRequestTable
                  data={requests}
                  isLoading={fetchingProjects}
                  onEdit={handleDetail}
                />
              </Grid>
            </Grid>
            <InventoryTransferRequestDetailModal
              id={detailId}
              open={showForm}
              onClose={handleCloseForm}
              onSubmit={handleSubmitForm}
            />
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default InventoryTransferRequestView;
