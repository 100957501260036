// Em ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { auth } from '../../firebase/firebase';
import Cookies from 'js-cookie';
import { Box, CircularProgress, Typography } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [sessionID, setSessionID] = useState(null);

  useEffect(() => {
    setSessionID(Cookies.get('sessionID'));

    const checkToken = async () => {
      await auth.authStateReady();
      if (!auth.currentUser) {
        navigate('/Authentication')
      }
      const tokenId = await auth.currentUser.getIdToken(true);
      let credentials = null;
      let session = null;

      if (!tokenId) {
        window.alert('Sessão expirada. Por favor, faça login novamente.');
        navigate('/Authentication');
      } else {
        credentials = await fetch(
          'https://sapglxauth-2fr44zihaq-uk.a.run.app',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          }
        ).then((response) => {
          if (response.status === 401) {
            window.alert('Sessão expirada. Por favor, faça login novamente.');
            navigate('/Authentication');
          } else if (response.status === 403) {
            window.alert('Você não tem permissão para acessar esta página.');
            navigate('/Authentication');
          } else if (response.status === 200) {
            return response.json();
          }
        }).catch((error) => {
          console.error('Erro:', error);
          window.alert('Erro ao verificar a sessão. Por favor, faça login novamente.');
          navigate('/Authentication');
        });

        if (credentials) {
          await fetch(
            'https://greylogix-sl.skyinone.net:50000/b1s/v1/Login',
            {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(credentials),
            }
          ).then((response) => {
            if (response.status === 200) {
              return response.json();
            }
          }).catch((error) => {
            console.error('Erro:', error);
          });
          session = btoa(`{"CompanyDB": "${credentials.CompanyDB}","UserName":"${credentials.UserName}"}:${credentials.Password}`);
          if (session) {
            Cookies.set(
              'sessionID',
              session,
              { expires: new Date(new Date().getTime() + session.SessionTimeout * 60 * 1000), sameSite: 'strict' }
            );
          }
        }

        if (session) {
          const apiURL = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
          apiURL.searchParams.append('$top', '1');
          apiURL.searchParams.append('$select', 'EmployeeID,LastName,FirstName,CostCenterCode,U_Portal_Role,U_EASY_ApontaEmLote');
          apiURL.searchParams.append('$filter', `eMail eq '${auth.currentUser.email}' and Active eq 'tYES'`);
          const employeeData = await fetch(
            apiURL,
            {
              method: 'GET',
              headers: {
                Authorization: `Basic ${session}`,
              },
              credentials: 'include',
            }
          ).then((response) => {
            if (response.status === 200) {
              return response.json();
            }
          }).catch((error) => {
            console.error('Erro:', error);
          });

          if (employeeData && employeeData.value.length > 0) {
            const employee = employeeData.value[0];
            Cookies.set(
              'user',
              JSON.stringify(employee),
              { expires: new Date(new Date().getTime() + 30 * 60 * 1000), sameSite: 'strict' }
            );
            Cookies.set('logemployeeID', employee.EmployeeID, { sameSite: 'strict' });
            Cookies.set('logemployeerole', employee.U_Portal_Role, { sameSite: 'strict' });
            Cookies.set('logemployeelote', employee.U_EASY_ApontaEmLote, { sameSite: 'strict' });
            Cookies.set('logemployeefirstname', employee.FirstName, { sameSite: 'strict' });
            Cookies.set('logemployeelastname', employee.LastName, { sameSite: 'strict' });
            Cookies.set('logemployeecostcenter', employee.CostCenterCode, { sameSite: 'strict' });
            setSessionID(session);
          } else {
            window.alert('Erro ao obter informações do usuário. Por favor, faça login novamente.');
            navigate('/Authentication');
          }
        }
      }
    }

    checkToken();

    const interval = setInterval(checkToken, 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, [navigate, location.pathname]);

  if (sessionID) {
    return children;
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box textAlign="center">
        <CircularProgress color="warning" />
        <Typography variant="h6">Obtendo credênciais de acesso...</Typography>
      </Box>
    </Box>
  );
};

export default ProtectedRoute;
