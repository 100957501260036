import React, { useEffect } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', flex: 0.2, },
  { field: 'Activity', headerName: 'Tarefa', minwidth: 200, flex: 0.5, },
  {
    field: 'Date',
    headerName: 'Data',
    valueGetter: (params) => params.row['Date'].format('DD-MM-YYYY'),
  },
  { field: 'StartTime', headerName: 'Início',  },
  { field: 'Break', headerName: 'Intervalo',  },
  { field: 'EndTime', headerName: 'Fim',  },
  { field: 'EffectiveTime', headerName: 'Horas',  },
  { field: 'U_Local', headerName: 'Local',  },
  { field: 'U_EASY_Obs', headerName: 'Observação', minwidth: 300, flex: 1,  },
];

const ApontamentoTable = ({ data, setApiRef }) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  useEffect(() => {
    data.map((row) => apiRef.current?.selectRow(row['id']));
  }, [data, apiRef]);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="#apt-table">Apontamentos</InputLabel>
      <DataGrid
        localeText={{ noRowsLabel: "Nenhum apontamento encontrado" }}
        apiRef={apiRef}
        density="compact"
        id="apt-table"
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        style={{ width: '100%', minHeight: '10rem' }}
        autoHeight
        sortingMode="server"
        sortModel={[{ field: 'Date', sort: 'asc' }]}
      />
    </FormControl>
  );
}

export default ApontamentoTable;
