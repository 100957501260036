import { Add, Edit, } from "@mui/icons-material";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";

const ItemsTable = ({ disabled, lines, onAdd, onEdit }) => {

  const handleAdd = (e) => {
    e.preventDefault();
    onAdd();
  }

  const handleEdit = (id) => {
    onEdit(id);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Button color="warning" disabled={disabled} onClick={handleAdd} size="small" variant="contained"><Add /></Button>
          </TableCell>
          <TableCell>Colab</TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Quantidade</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'auto' }}>
        {lines.map((stage, index) => {
          return (
            <TableRowComponent
              key={index}
              line={stage}
              index={index}
              onEdit={handleEdit}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

function TableRowComponent({ line, index, onClick }) {

  const handleClick = (e) => {
    e.preventDefault();
    onClick(index);
  }

  return (
    <TableRow style={{ backgroundColor: index % 2 > 0 ? '#F1F1F1' : 'inherit' }}>
      <TableCell><IconButton disabled onClick={handleClick} size="small"><Edit /></IconButton></TableCell>
      <TableCell>{line['U_EASY_Colab']}</TableCell>
      <TableCell>{line['ItemLabel']}</TableCell>
      <TableCell>{line['Quantity']}</TableCell>
    </TableRow>
  );
}

ItemsTable.defaultProps = {
  disabled: true,
  lines: [],
  onAdd: () => { },
  onEdit: () => { },
};

export default ItemsTable;