import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Backdrop, Box, Button, Card, CircularProgress, FormControl,
  Grid, Icon, InputLabel, TextField, createTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import LogProdFormModal from './form';
import ApontamentosOPTable from './table';
import { fetchEmployees, fetchOpenProductionOrders, fetchInvGenExits, fetchProjects, statuses } from './fetchData';
import { exportApontamentosOPToXLSX } from './exportData';
import dynTheme from 'assets/dynTheme';

function LogProdView() {

  const isManager = Cookies.get('logemployeerole') === 'Manager';

  const [refresh, setRefresh] = useState(false);

  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const [employee, setEmployee] = useState(null);
  const [project, setProject] = useState(null);
  const [status, setStatus] = useState([statuses[0]]);
  const [productionOrder, setProductionOrder] = useState(null);
  const [startDate, setStartDate] = useState(isManager ? dayjs() : dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs());

  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openProductionOrders, setOpenProductionOrders] = useState([]);
  const [fetchingEmployees, setFetchingEmployees] = useState(false);
  const [fetchingProjects, setFetchingProjects] = useState(false);
  const [fetchingProductionOrders, setFetchingProductionOrders] = useState(false);

  const [invGenExits, setInvGenExits] = useState([]);
  const [fetchingInvGenExits, setFetchingInvGenExits] = useState(false);


  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    if (isManager) {
      setFetchingEmployees(true);
      fetchEmployees(sessionID).then((data) => {
        if (data != null) {
          setEmployees(data);
        }
      }).finally(() => setFetchingEmployees(false));
    } else {
      setEmployee({
        EmployeeID: Cookies.get('logemployeeID'),
        FirstName: Cookies.get('logemployeefirstname'),
        LastName: Cookies.get('logemployeelastname'),
        label: `${Cookies.get('logemployeeID')} - ${Cookies.get('logemployeefirstname')} ${Cookies.get('logemployeelastname')}`,
      })
    }
    setFetchingProjects(true);
    fetchProjects(sessionID).then((data) => {
      if (data != null) {
        setProjects(data);
      }
    }).finally(() => setFetchingProjects(false));
  }, []);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingProductionOrders(true);
    fetchOpenProductionOrders(sessionID, project?.Code, status?.map((s) => s.simple)).then((data) => {
      if (data != null) {
        setOpenProductionOrders(data);
      }
    }).finally(() => setFetchingProductionOrders(false));
  }, [project, status]);


  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    if (isManager || employee !== null) {
      setFetchingInvGenExits(true);
      fetchInvGenExits(
        sessionID,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        employee?.EmployeeID,
        project?.Code,
        status?.map((s) => s.complex),
        productionOrder?.AbsoluteEntry,
      ).then((data) => {
        if (data != null) {
          setInvGenExits(data);
        }
      }).finally(() => {
        setFetchingInvGenExits(false);
        setRefresh(false);
      });
    }
  }, [employee, project, status, productionOrder, startDate, endDate, refresh]);

  const handleOpenAddForm = (e) => {
    e.preventDefault();
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setEditId(null);
  }

  const handleSubmitForm = () => {
    handleCloseForm();
    setRefresh(true);
  }

  const handleChangeEmployee = (e, value) => {
    e.preventDefault();
    setEmployee(value);
  }

  const handleChangeProject = (e, value) => {
    e.preventDefault();
    setProject(value);
  }

  const handleChangeStatus = (e, value) => {
    e.preventDefault();
    setStatus(value);
  }

  const handleChangeProductionOrder = (e, value) => {
    e.preventDefault();
    setProductionOrder(value);
  }

  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  const handleExportRows = (e) => {
    e.preventDefault();
    exportApontamentosOPToXLSX(invGenExits);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Apontamentos de Fábrica
              </MDTypography>
            </Box>
            <Grid container spacing={2} style={{ width: 'auto' }}>
              <Grid item xs={12} md={4}>
                <MDButton fullWidth variant="gradient" color="warning" onClick={handleOpenAddForm}>
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>add</Icon>
                  Novo
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton disabled fullWidth variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>upload</Icon>
                  Importar
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton disabled fullWidth variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>tablechart</Icon>
                  Template
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      disabled={!isManager}
                      fullWidth
                      loadingText="Carregando..."
                      onChange={handleChangeEmployee}
                      options={employees}
                      renderInput={(params) => <TextField {...params} label="Colaborador" />}
                      value={employee}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      fullWidth
                      loadingText="Carregando..."
                      onChange={handleChangeProject}
                      options={projects}
                      renderInput={(params) => <TextField {...params} label="Projeto" />}
                      value={project}
                    />
                  </Grid>
                  <Grid item xs={0} md={4} />
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      fullWidth
                      loadingText="Carregando..."
                      multiple
                      onChange={handleChangeStatus}
                      options={statuses}
                      renderInput={(params) => <TextField {...params} label="Status" />}
                      value={status}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      fullWidth
                      loadingText="Carregando..."
                      onChange={handleChangeProductionOrder}
                      options={openProductionOrders}
                      renderInput={(params) => <TextField {...params} label="Ordem Produção" />}
                      value={productionOrder}
                    />
                  </Grid>
                  <Grid item xs={0} md={4} />
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-inicio" label="Data Início" onChange={handleChangeStartDate} value={startDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0} md={4} />
                  <Grid item xs={12} md={2}>
                    <Button
                      disabled={invGenExits.length === 0}
                      fullWidth color="warning" size="large" variant="outlined"
                      onClick={handleExportRows}
                    >
                      <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>download</Icon>
                      Exportar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <ApontamentosOPTable data={invGenExits} isLoading={fetchingInvGenExits} />
              </Grid>
            </Grid>
            <LogProdFormModal
              open={showForm}
              onClose={handleCloseForm}
              onSubmit={handleSubmitForm}
              employees={employees}
              productionOrders={openProductionOrders}
              fixedEmployee={isManager ? null : employee}
            />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={fetchingEmployees || fetchingProductionOrders || fetchingProjects}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default LogProdView;
