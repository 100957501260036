import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions,
  DialogContent, Divider, FormControlLabel, Grid, IconButton,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel, Send } from '@mui/icons-material';
import Cookies from 'js-cookie';

import { useStatus } from '../../hooks/useStatus';
import { fetchRequest, fetchStockTranfers, createStockTransfer } from './fetchData';
import TransfersTable from './subtable';
import TransferSubForm from './subform';

const InventoryTransferRequestDetailModal = ({ open, onClose, onSubmit, id }) => {

  const [docNum, setDocNum] = useState('');
  const [fromWarehouse, setFromWarehouse] = useState('');
  const [toWarehouse, setToWarehouse] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [comments, setComments] = useState('');
  const [showTransfereds, setShowTransfereds] = useState(false);
  const [barcode, setBarcode] = useState('');

  const [lines, setLines] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [pickings, setPickings] = useState([]);
  const [codes, setCodes] = useState({});

  const { status, toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [fetchingRequest, setFetchingRequest] = useState(false);
  const [fetchingTransfers, setFetchingTransfers] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setRefresh(false);
    if (id && !fetchingRequest && !fetchingTransfers) {
      const sessionID = Cookies.get('sessionID');
      setFetchingRequest(true);
      fetchRequest(sessionID, id).then((data) => {
        if (data != null) {
          setDocNum(data['DocNum']);
          setFromWarehouse(data['FromWarehouse']);
          setToWarehouse(data['ToWarehouse']);
          setDueDate(data['DueDate']);
          setComments(data['Comments']);
          setLines(data['StockTransferLines'].map((line) => ({
            id: line['LineNum'],
            ...line,
            'total': `${line['Quantity'] - line['RemainingOpenQuantity']}/${line['Quantity']}`,
          })).sort((a, b) => {
            if (a['InStock'] <= 0 && b['InStock'] > 0) {
              return 1;
            } else if (a['InStock'] > 0 && b['InStock'] <= 0) {
              return -1;
            }
            return 0;
          }));
        }
      }).finally(() => setFetchingRequest(false));
      setFetchingTransfers(true);
      fetchStockTranfers(sessionID, id).then((data) => {
        if (data != null) {
          setTransfers(data);
        }
      }).finally(() => setFetchingTransfers(false));
    }
  }, [id, refresh]);

  useEffect(() => {
    const hashes = {};
    lines.forEach((line, index) => {
      hashes[line['ItemCode']] = index;
    });
    setCodes(hashes);
  }, [lines]);

  useEffect(() => {
    let items = [];
    lines.forEach((line) => {
      const item = pickings.find((p) => p['BaseLine'] === line['id']);
      const transfered = line['Quantity'] - line['RemainingOpenQuantity'];
      if (item) {
        line['total'] = `${item['Quantity'] - transfered}/${line['Quantity']}`;
      } else {
        line['total'] = `${transfered}/${line['Quantity']}`;
      }
      items.push(line);
    });
    setLines(items);
  }, [pickings])

  useEffect(() => {
    if (barcode) {
      let bar = barcode.replace(/=/g, '-');
      const index = codes[bar];
      if (index !== undefined) {
        handleEditForm(index);
      } else {
        toggleStatus('Código de barras não encontrado!', `Verifique se o código ${bar} está cadastrado e tente novamente.`, 'warning');
      }
      setBarcode('');
    }
  }, [barcode]);

  const handleRemovePicking = (index) => {
    const line = pickings[index];
    setPickings(pickings.filter((p) => p['BaseLine'] !== line['BaseLine']));
  }

  const handleToggleTransfereds = (e) => {
    setShowTransfereds(!showTransfereds);
  }

  const handleReset = () => {
    setDocNum('');
    setFromWarehouse('');
    setToWarehouse('');
    setDueDate('');
    setComments('');
    setLines([]);
    setTransfers([]);
    setPickings([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleEditForm = (index) => {
    setEditIndex(index);
    setShowEdit(true);
  }

  const handleCloseForm = () => {
    setEditIndex(null);
    setShowEdit(false);
  }

  const handleFormSubmit = (line) => {
    if (line['BatchNumbers'].length > 0) {
      setPickings([...pickings, line]);
    }
    setEditIndex(null);
    setShowEdit(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving('Salvando');
    const sessionID = Cookies.get('sessionID');
    const data = {
      "StockTransferLines": pickings
    };
    createStockTransfer(sessionID, data).then((response) => {
      console.log(response);
      handleReset();
      onSubmit();
      onClose();
      toggleStatus('Transferência salva com sucesso!', `Criada transferência Nº ${response['DocNum']}`, 'success');
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar transferências!', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const handleChangeBarcode = useCallback((value) => {
    setBarcode(value);
  }, []);

  const highlightPickingCallback = (line) => {
    for (let i = 0; i < pickings.length; i++) {
      const p = pickings[i];
      if (p['BaseLine'] === line['id']) {
        if (line['RemainingOpenQuantity'] >= p['Quantity']) {
          return 'linear-gradient(#0F02, #0F02)';
        }
        return 'linear-gradient(#FF02, #FF02)'
      }
    }
    return 'inherit';
  };

  const highlightTransferCallback = (picking) => {
    if (picking['id'] === undefined) {
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (picking['BaseLine'] === line['id']) {
          if (line['RemainingOpenQuantity'] >= picking['Quantity']) {
            return 'linear-gradient(#0F02, #0F02)';
          }
          return 'linear-gradient(#FF02, #FF02)';
        }
      }
    }
    return 'inherit';
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Solicitação de Picking de Inventário
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="ID" value={docNum} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Do Depósito" value={fromWarehouse} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Para Depósito" value={toWarehouse} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Data Fim" value={dueDate} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField fullWidth label="Comentários" value={comments} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={<Checkbox checked={showTransfereds} color="warning" onChange={handleToggleTransfereds} />}
              label="Mostrar Transferidos"
            />
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <BarCodeInput canFocus={!showEdit && !status.isOpen} onChange={handleChangeBarcode} />
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" component="div" mt={2}>Requisição Interna</Typography>
            <TransfersTable
              lines={lines}
              icon={<Send />}
              onClick={handleEditForm}
              showMU
              filterCallback={(line) => showTransfereds ? true : line['RemainingOpenQuantity'] > 0}
              highlightCallback={highlightPickingCallback}
              enabledCallback={(line) => line['InStock'] > 0}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" component="div" mt={2}>Tranferidos</Typography>
            <TransfersTable
              lines={[...pickings, ...transfers]}
              icon={<Cancel />}
              onClick={handleRemovePicking}
              enabledCallback={(line) => line['id'] === undefined}
              filterCallback={(line) => showTransfereds ? true : line['id'] === undefined}
              highlightCallback={highlightTransferCallback}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" onClick={() => setPickings([])} variant="text">Limpar</Button>
        <Button color="warning" disabled={pickings.length === 0} onClick={handleSubmit} variant="contained">Salvar</Button>
      </DialogActions>
      <TransferSubForm
        open={showEdit}
        onClose={handleCloseForm}
        onSubmit={handleFormSubmit}
        line={lines[editIndex]}
      />
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingRequest || fetchingTransfers} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

InventoryTransferRequestDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

function BarCodeInput({ canFocus, onChange }) {

  const handleEnter = useCallback((e) => {
    if (e.key === 'Enter') {
      const val = "" + e.target.value;
      onChange(val);
      e.preventDefault();
      e.target.value = "";
    }
  }, [onChange]);

  const handleFocus = useCallback((e) => {
    if (canFocus) {
      e.target.focus({ preventScroll: true });
    }
  }, [canFocus]);

  return (
    <TextField
      autoFocus
      fullWidth
      defaultValue=""
      inputProps={{ inputMode: 'none' }}
      label="Código de Barras"
      onBlur={handleFocus}
      onKeyUp={handleEnter}
      variant="outlined"
    />
  );
}

export default InventoryTransferRequestDetailModal;
