import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

export function exportApontamentosOPToXLSX(data) {
  const header = ['Projeto', 'Nome do Projeto', 'Nº OP', 'Descrição OP', 'Colaborador', 'Serviço', 'Data', 'HT'];
  const rows = [];

  data.forEach((item) => {
    rows.push([
      item['Projects']['Code'],
      item['Projects']['Name'],
      item['ProductionOrders']['ItemNo'],
      item['ProductionOrders']['ProductDescription'],
      item['EmployeesInfo']['FirstName'] + ' ' + item['EmployeesInfo']['LastName'],
      item['ProductionOrders/ProductionOrderLines']['ItemName'],
      item.date,
      item.qtd
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'ApontamentosFabrica');
  XLSX.writeFile(wb, `${dayjs().format('YYYY-MM-DD-HH-mm')}-ApontamentosFabrica.xlsx`);
};