import React from 'react';
import {
  Alert, Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, createTheme,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';

import { useStatus } from '../hooks/useStatus';

export const Status = () => {
  const { status, toggleStatus } = useStatus();

  if (!status.isOpen) return null;

  const handleClose = (e) => {
    e.preventDefault();
    if (status.callback) {
      status.callback();
    }
    toggleStatus();
  };

  const renderMessage = (title, value) => (
    <blockquote>
      <b>{title}</b>
      <p>{value}</p>
    </blockquote>
  );

  const renderObject = (message) => {
    if (message.error) {
      return renderMessage('SAP', message.error.message.value)
    }
    return (
      <code>
        {JSON.stringify(message, null, 2)}
      </code>
    )
  };

  const renderArray = (message) => {
    return (
      <ul>
        {message.map((item, index) => {
          if (item.status === "rejected") {
            return (
              <li key={index} style={{ margin: '1vh' }}>
                <Alert severity="error">
                  <b>Linha {index + 2}</b> - {renderObject(item.reason)}
                </Alert>
              </li>
            );
          }
          return (
            <li key={index} style={{ margin: '1vh' }}>
              <Alert severity="success">
                <b>Linha {index + 2}</b> - Sucesso
              </Alert>
            </li>
          );
        })}
      </ul>
    );
  }

  const renderContent = () => {
    if (typeof status.message === 'string') {
      return <Alert severity={status.type}><p>{status.message}</p></Alert>;
    }
    if (Array.isArray(status.message)) {
      return renderArray(status.message);
    }
    return <Alert severity={status.type}>{renderObject(status.message)}</Alert>;
  }

  return (
    <ThemeProvider theme={createTheme()}>
      <Dialog open={status.isOpen} onClose={handleClose} fullWidth maxWidth="lg" sx={{ zIndex: (t) => t.zIndex.modal + 100 }}>
        <DialogTitle>
          {status.title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {renderContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="warning" variant="outlined" onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
