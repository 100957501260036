import React, { memo, useCallback } from 'react';
import {
  CircularProgress, IconButton, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

const TimeSheetTable = memo(function TimeSheetTable({ data, isLoading, onEdit }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Data</TableCell>
            <TableCell>Colaborador</TableCell>
            <TableCell>Projeto</TableCell>
            <TableCell>Tarefa</TableCell>
            <TableCell>Inicio</TableCell>
            <TableCell>Intervalo</TableCell>
            <TableCell>Fim</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Local</TableCell>
            <TableCell>Feriado</TableCell>
            <TableCell>Comentários</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={12} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Edit /></IconButton></TableCell>
              <TableCell>{item['Date']}</TableCell>
              <TableCell>{item['Employee']}</TableCell>
              <TableCell>{item['Project']}</TableCell>
              <TableCell>{item['Activity']}</TableCell>
              <TableCell>{item['StartTime']}</TableCell>
              <TableCell>{item['Break']}</TableCell>
              <TableCell>{item['EndTime']}</TableCell>
              <TableCell>{item['EffectiveTime']}</TableCell>
              <TableCell>{item['Local']}</TableCell>
              <TableCell>{item['Holiday']}</TableCell>
              <TableCell>{String(item['Observation']).slice(0, 50)}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={12} align="center">
                Nenhum apontamento encontrado
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default TimeSheetTable;
