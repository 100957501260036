import { useEffect, useState } from "react";
import { Add, Check, Close, Edit, LabelOutlined } from "@mui/icons-material";
import { Autocomplete, Button, ButtonGroup, FormControl, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { activities } from "layouts/TimeSheet/fetchData";
import { stageTypes } from "./fetchData";

const StagesTable = ({ canAdd, stages }) => {

  useEffect(() => {
    setLines(stages);
  }, [stages]);

  const [editId, setEditId] = useState(null);
  const [lines, setLines] = useState(stages);

  const handleAdd = () => {
    const last = lines[lines.length - 1];
    setLines([...lines, {
      id: last ? last.id + 1 : 1, Task: 1, Description: 'Assistência', start: dayjs(),
      end: null, cost: 0,
    }]);
    setEditId(lines.length);
  }

  const handleEdit = (id) => {
    setEditId(id);
  }

  const handleCancel = () => {
    setEditId(null);
  }

  const handleSave = (id, data) => {
    setEditId(null);
    const newLines = [...lines];
    newLines[id] = data;
    setLines(newLines);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Button color="warning" disabled onClick={handleAdd} size="small" variant="contained"><Add /></Button>
          </TableCell>
          <TableCell>Estágio</TableCell>
          <TableCell>Tipo</TableCell>
          <TableCell>Atividade</TableCell>
          <TableCell>Início</TableCell>
          <TableCell>Fim</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'auto' }}>
        {lines.map((stage, index) => {
          if (editId === index) {
            return (
              <TableEditRowComponent
                key={index}
                line={stage}
                index={index}
                onCancel={handleCancel}
                onSave={handleSave}
              />
            );
          }
          return (
            <TableRowComponent
              key={index}
              line={stage}
              index={index}
              onEdit={handleEdit}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

function TableRowComponent({ line, index, onEdit }) {

  const handleEdit = (e) => {
    e.preventDefault();
    onEdit(index);
  }

  return (
    <TableRow style={{ backgroundColor: index % 2 > 0 ? '#F1F1F1' : 'inherit' }}>
      <TableCell><IconButton disabled onClick={handleEdit} size="small"><Edit /></IconButton></TableCell>
      <TableCell>{line['id']}</TableCell>
      <TableCell>{line['type']?.label || '-'}</TableCell>
      <TableCell>{line['task']?.label || '-'}</TableCell>
      <TableCell>{line['start']?.format('DD/MM/YYYY') || '-'}</TableCell>
      <TableCell>{line['end']?.format('DD/MM/YYYY') || '-'}</TableCell>
    </TableRow>
  );
}

function TableEditRowComponent({ line, index, onCancel, onSave }) {

  const [type, setType] = useState(line['type']);
  const [task, setTask] = useState(line['task']);
  const [start, setStart] = useState(line['start']);
  const [end, setEnd] = useState(line['end']);

  const handleSave = (e) => {
    e.preventDefault();
    onSave(index, {
      ...line,
      Task: task.id, Description: task.label, start: start.format('YYYY-MM-DD'),
      end: end?.format('YYYY-MM-DD'),
    });
  }

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel(index);
  }

  return (
    <TableRow>
      <TableCell>
        <ButtonGroup>
          <IconButton onClick={handleSave} size="small"><Check /></IconButton>
          <IconButton onClick={handleCancel} size="small"><Close /></IconButton>
        </ButtonGroup>
      </TableCell>
      <TableCell><TextField disabled size="small" value={line['id']} /></TableCell>
      <TableCell component="th" scope="row" style={{ minWidth: '20vw' }}>
        <Autocomplete
          fullWidth
          options={stageTypes} value={type}
          onChange={(e, value) => setType(value)}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option) => <li {...props}>{`${option.id} - ${option.label}`}</li>}
          size="small"
        />
      </TableCell>
      <TableCell component="th" scope="row" style={{ minWidth: '20vw' }}>
        <Autocomplete
          fullWidth
          options={activities} value={task}
          onChange={(e, value) => setTask(value)}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option) => <li {...props}>{`${option.id} - ${option.label}`}</li>}
          size="small"
        />
      </TableCell>
      <TableCell>
        <FormControl size="small">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <DatePicker
              id="start"
              slotProps={{ textField: { size: 'small' } }}
              onChange={setStart}
              value={start}
            />
          </LocalizationProvider>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl size="small">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <DatePicker
              id="end"
              slotProps={{ textField: { size: 'small' } }}
              onChange={setEnd}
              value={end}
            />
          </LocalizationProvider>
        </FormControl>
      </TableCell>
    </TableRow>
  );
}

export default StagesTable;