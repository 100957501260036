import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import * as XLSX from "xlsx";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Cookies from 'js-cookie';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';

import Card from "@mui/material/Card";

import React, { Component } from 'react';
import $ from 'jquery';

import LogModal from 'react-modal';

import { ExpandMore, ExpandLess } from '@mui/icons-material';


class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { item, children } = this.props;
    const { expanded } = this.state;

    return (
      <div >
        <TableRow>
          <div onClick={this.toggleExpanded}>

            <TableCell align="center" style={{ width: '50px' }}>{expanded ? <ExpandLess /> : <ExpandMore />}</TableCell>
            <TableCell align="left" style={{ width: '800px' }}><MDTypography variant="h6">{item.TreeCode} - {item.ProductDescription != null ? item.ProductDescription : '-'}  </MDTypography>   </TableCell>

          </div>
          {expanded && children}
        </TableRow>
      </div>
    );
  }
}




class ProductTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductTreeData: [],
      currentPage: 1,
      isLoading: false,
      showAddForm: false,
      Name: '',
      Description: '',
      sessionId: null,
    };
  }

  handleRefreshButton = (e) => {
    console.log('handleRefreshButton called');

    this.setState(
      { ProductTreeData: [], currentPage: 1 }, // Clear previous data and reset page
      () => {
        console.log(this.state.currentPage);
        this.getProductTreeData();
      }
    );
  };

  handleDownloadClick = () => {
    // Use the public URL for your template file
    const templateUrl = process.env.PUBLIC_URL + "/importptree.xlsx";
    const link = document.createElement("a");
    link.href = templateUrl;
    link.download = "importptree.xlsx"; // Specify the filename for the downloaded file.
    link.click();
  };

  handleImportButtonClick = (e) => {
    // Trigger the hidden file input element
    this.fileInput.click();
  };



  handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const rows = XLSX.utils.sheet_to_json(sheet);

      const groupedData = {};
      rows.forEach((row) => {
        const { TreeCode, Warehouse, Project, ItemCode, Quantity } = row;
        if (!groupedData[TreeCode]) {
          groupedData[TreeCode] = {
            TreeCode,
            Warehouse,
            Project,
            ProductTreeLines: [],
          };
        }
        groupedData[TreeCode].ProductTreeLines.push({ ItemCode, Quantity, Project });
      });

      Object.values(groupedData).forEach((group) => {
        const { TreeCode, Warehouse, Project, ProductTreeLines } = group;
        this.sendPostRequest(TreeCode, Warehouse, Project, ProductTreeLines);
      });
    };

    reader.readAsArrayBuffer(file);
  };

  sendPostRequest = (TreeCode, Warehouse, Project, ProductTreeLines) => {
    const { sessionId } = this.state;

    const postData = {
      TreeCode,
      TreeType: "iProductionTree",
      Warehouse,
      Project,
      ProductTreeLines: ProductTreeLines.map((line) => ({
        ItemCode: line.ItemCode,
        Warehouse,
        Quantity: parseInt(line.Quantity),
        Project: line.Project,
      })),
    };

    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees`,
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Success:', JSON.stringify(postData, null, 2));
        // Handle success response here
      },
      error: (error) => {
        console.log('ERROR:', JSON.stringify(postData, null, 2));
        // Handle error here
      },
    });
  };



  addProductTree = () => {
    this.setState({
      showAddForm: true,
    });
  };

  closeAddProductTree = () => {
    this.setState({
      showAddForm: false,
    });
  };

  componentDidMount() {
    // Set app element for react-modal (must be done once)
    const LoggedUserID = Cookies.get('logemployeeID');
    console.log('ProductTreeComponent mounted');
    LogModal.setAppElement('#app'); // Replace '#root' with the appropriate element

    const sessionID = Cookies.get('sessionID');

    this.setState({ sessionId: sessionID }, () => {

      this.setState({
        ProductTreeData: [],
        currentPage: 1
      }, () => {
        this.getProductTreeData()
      });

    });

  }

  handleAddProductTreeSubmit = () => {
    console.log('Submit button clicked');
    const { sessionId } = this.state;

    const postData = {
      //Name: this.state.Name,
      //Description: this.state.Description,
    };

    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees`,
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Request Body:', JSON.stringify(postData, null, 2));

        this.setState({
          showAddForm: false,
        });
      },
      error: (error) => {
        console.error('Error:', error);
        console.log('Request Body:', JSON.stringify(postData, null, 2));
      },
    });
  };

  getProductTreeData = () => {
    const { sessionId } = this.state;
    $("#okAlert").fadeOut();
    $("#errorAlert").fadeOut();
    const { currentPage, isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    const skip = (currentPage - 1) * 20; // Adjust the page size here (20 in this case)
    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees?$skip=${skip}`;
    // Construct the headers
    const headers = {
      Authorization: `Bearer ${sessionId}`,
    };

    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',

      success: (response) => {
        const newData = response.value || [];

        this.setState((prevState) => ({
          ProductTreeData: [...newData.reverse(), ...prevState.ProductTreeData],
          isLoading: false,
        }));

        if (newData.length === 20) {
          // If you received 20 records, there might be more, so increment the page
          this.setState(
            (prevState) => ({ currentPage: prevState.currentPage + 1 }),
            this.getProductTreeData
          );
        }
      },
      error: (error) => {
        console.error('Error:', error);

        this.setState({ isLoading: false });
      },
    });
  };

  renderTree() {
    const { ProductTreeData, isLoading } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    return (
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info"   >
                <MDTypography variant="h6" color="white">
                  Conjuntos Típicos
                </MDTypography>
              </MDBox>
              <tr>
                <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ChildNum</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ItemType</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '300px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ItemCode</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '700px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ItemName</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >Quantity</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >Price</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >Project</MDTypography></TableCell>
              </tr>

              {ProductTreeData.map((item, parentIndex) => (
                <TreeNode key={parentIndex} item={item}>
                  {item.ProductTreeLines.map((line, lineIndex) => (
                    <tr key={`${parentIndex}_${lineIndex}`}>
                      <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ChildNum != null ? line.ChildNum : '-'}</MDTypography></TableCell>
                      <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemType != null ? line.ItemType : '-'}</MDTypography></TableCell>
                      <TableCell align="center" style={{ width: '300px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemCode != null ? line.ItemCode : '-'}</MDTypography></TableCell>
                      <TableCell align="center" style={{ width: '700px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemName != null ? line.ItemName : '-'}</MDTypography></TableCell>
                      <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.Quantity != null ? line.Quantity : '-'}</MDTypography></TableCell>
                      <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.Price != null ? line.Price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</MDTypography></TableCell>
                      <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.Project != null ? line.Project : '-'}</MDTypography></TableCell>
                    </tr>

                  ))}
                </TreeNode>
              ))}



            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }


  render() {
    return (

      <DashboardLayout>
        <DashboardNavbar />

        <MDButton variant="gradient" color="warning" onClick={this.handleRefreshButton} >
          <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
          &nbsp; Carregar Conjuntos
        </MDButton>

        <input
          type="file"
          accept=".xlsx"
          ref={(input) => (this.fileInput = input)} // Create a reference to the file input
          style={{ display: "none" }} // Hide the file input
          onChange={this.handleFileInputChange}
        />

        <MDButton variant="gradient" color="warning" onClick={this.handleImportButtonClick} >
          <Icon sx={{ fontWeight: "bold" }}>publish</Icon>
          &nbsp;Importar Conjuntos
        </MDButton>

        <MDButton variant="gradient" color="warning" onClick={this.handleDownloadClick} >
          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
          &nbsp;Template
        </MDButton>


        {this.renderTree()}
        <LogModal
          isOpen={this.state.showAddForm}
          onRequestClose={this.closeAddProductTree}
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div>
            <h2>Adicionar ProductTreeo</h2>
            <label>Name:</label>
            <input
              type="text"
              value={this.state.Name || ''}
              onChange={(e) => this.setState({ Name: e.target.value })}
              placeholder=""
              className="black-input"
            />
            <div>
              <label>Description:</label>
              <input
                type="text"
                value={this.state.Description || ''}
                onChange={(e) => this.setState({ Description: e.target.value })}
                placeholder=""
                className="black-input"
              />
            </div>
            <button className="button" onClick={this.handleAddProductTreeSubmit}>
              Submeter
            </button>
            <button className="button" onClick={this.closeAddProductTree}>
              Cancelar
            </button>
          </div>
        </LogModal>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default ProductTree;

