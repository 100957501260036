import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import Cookies from "js-cookie";

const TransfersTable = ({ lines, icon, showMU, onClick, enabledCallback, filterCallback, highlightCallback }) => {

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell>Qtd.</TableCell>
          <TableCell sx={showMU ? {} : {display: 'none'}}>Saldo</TableCell>
          <TableCell sx={showMU ? {} : {display: 'none'}}>UM</TableCell>
          <TableCell align="center" />
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'auto' }}>
        <RenderlinesRows
          lines={lines}
          onClick={onClick}
          icon={icon}
          showMU={showMU}
          enabledCallback={enabledCallback}
          filterCallback={filterCallback}
          highlightCallback={highlightCallback}
        />
      </TableBody>
    </Table>
  );
};

function RenderlinesRows({ lines, onClick, icon, showMU, enabledCallback, filterCallback, highlightCallback }) {
  const rows = [];
  for (let i = 0; i < lines.length; i++) {
    if (!filterCallback(lines[i])) {
      continue;
    }
    rows.push(
      <TableRowComponent
        disabled={enabledCallback ? !enabledCallback(lines[i]) : false}
        index={i}
        key={i}
        line={lines[i]}
        onClick={onClick}
        icon={icon}
        showMU={showMU}
        highlight={highlightCallback(lines[i])}
      />
    );
  }
  if (rows.length === 0) {
    rows.push(
      <TableRow><TableCell align="center" colSpan={4} variant="footer" >Nenhuma transferência encontrada</TableCell></TableRow>
    );
  }
  return rows;
}

const bg_highlight = Cookies.get('darkMode') === 'true' ? '#515151' : '#F1F1F1';

function TableRowComponent({ disabled, line, index, onClick, icon, showMU, highlight }) {

  const handleClick = (e) => {
    e.preventDefault();
    onClick(index);
  }

  return (
    <TableRow sx={{
      backgroundColor: index % 2 > 0 ? bg_highlight : 'inherit',
      backgroundBlendMode: 'darken',
      backgroundImage: highlight, 
    }}>
      <TableCell>{line['ItemCode']}</TableCell>
      <TableCell>{line['total'] || line['Quantity']}</TableCell>
      <TableCell sx={showMU ? {} : {display: 'none'}}>{line['InStock']}</TableCell>
      <TableCell sx={showMU ? {} : {display: 'none'}}>{line['MeasureUnit']}</TableCell>
      <TableCell align="center">
        <IconButton disabled={disabled} onClick={handleClick} size="small">{icon}</IconButton>
      </TableCell>
    </TableRow>
  );
}

TransfersTable.defaultProps = {
  lines: [],
  showMU: false,
  onClick: () => {},
  enabledCallback: () => true,
  filterCallback: () => true,
  highlightCallback: () => false,
};

export default TransfersTable;
