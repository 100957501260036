import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const MonetaryInput = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        valueIsNumericString
      />
    );
  },
);

MonetaryInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

MonetaryInput.defaultProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  prefix: 'R$'
};

export default MonetaryInput;
