import Icon from "@mui/material/Icon";
import React, { useState, useEffect  } from "react";
import $ from "jquery";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import useAuth from "../../hooks/useAuth"; // Importando o hook useAuth
import MenuItem from "@mui/material/MenuItem";
import MDButton from "components/MDButton";
import Cookies from 'js-cookie'; 
import {Dialog,DialogTitle,DialogContent,Select} from '@mui/material';
import bgImage from "assets/images/sap.png";
import app1 from "assets/images/app1.png";
import app2 from "assets/images/app2.png";
import app3 from "assets/images/app3.png";
import Switch from "@mui/material/Switch";
import {  useMaterialUIController,  setDarkMode} from "context";


const SessionID = () => {
  const { user } = useAuth(); // Obtendo informações do usuário do hook useAuth
  const [sessionID, setSessionID] = useState(null);
  const [companyDB, setCompanyDB] = useState("SBO_GREYLOGIX_PRD");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [lastName, setLastName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const {    darkMode,  } = controller;

  useEffect(() => {
    fetchSessionID(); // Fetch sessionID when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleDarkMode = () => {
    Cookies.set('darkMode', !darkMode);
    setDarkMode(dispatch, !darkMode);
  };

   
  const downloadlogtutorial = () => {
    const manualpdf = process.env.PUBLIC_URL + '/MANUAL_APONTAMENTOS_PORTAL_R01.pdf';
    const link = document.createElement('a');
    link.href = manualpdf;
    link.download = 'MANUAL_APONTAMENTOS_PORTAL_R01.pdf';
    link.click();
  };


  const downloadapptutorial = () => {
    const manualpdf = process.env.PUBLIC_URL + '/MANUAL_INSTALAR_APP_R0.pdf';
    const link = document.createElement('a');
    link.href = manualpdf;
    link.download = 'MANUAL_INSTALAR_APP_R0.pdf';
    link.click();
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCompanyDBChange = (event) => {
    setCompanyDB(event.target.value);
  };

  const fetchSessionID = () => {
    const sessionID = Cookies.get('sessionID');
    getEmployeeData(sessionID);

    setSessionID(sessionID);
  };


   const getEmployeeData = (sessionID) => {  

    const useremail = Cookies.get('useremail'); 
    
    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo?$top=1&$filter=eMail eq '${useremail}'`;
  
  
    const headers = {
      Authorization: `Basic ${sessionID}`,
    };
    
    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      dataType: 'json',
 
      success: (response) => {
        console.log(response.value);

        if (response.value && response.value.length > 0) {
          const employeeLastName = response.value[0].LastName;
          const employeeFirstName = response.value[0].FirstName;
          setLastName(employeeLastName); // Set last name in state
          setFirstName(employeeFirstName);

          const logemployeeID = response.value[0].EmployeeID;
          const logemployeerole = response.value[0].U_Portal_Role;
          const logemployelote = response.value[0].U_EASY_ApontaEmLote;

          Cookies.set('logemployeeID', logemployeeID, { sameSite: 'strict' }); 
          Cookies.set('logemployeerole', logemployeerole, { sameSite: 'strict' });
          Cookies.set('logemployeefirstname', response.value[0].FirstName, { sameSite: 'strict' });
          Cookies.set('logemployeelastname', response.value[0].LastName, { sameSite: 'strict' });
          Cookies.set('logemployeelote', logemployelote, { sameSite: 'strict' });

        } else {
          console.log('No employee data found');
        }
      },
      error: (error) => {
        console.error('Error:', error);

      },

    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

       {/* Add the image element */}
       

     
     
      <MDBox mt={6} mb={3}>
     
      <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Definir Tema: Claro/Escuro  <Switch checked={darkMode} onChange={handleDarkMode} /></MDTypography>
        
        </MDBox> 
       
        <Grid container spacing={3} >
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h3">

                <p>
              {/* Display last name if available */}
              {lastName ? (
                <span>Seja bem vindo  {firstName} {lastName} <img src={bgImage} alt="SAP Logo" style={{ width: '100%', maxWidth: '80px', height: 'auto' }} />            
                
                </span>
                


              ) : (
                <span>Seu usuário ainda não foi encontrado no SAP...    </span>
                
              )}

          
              
            </p>
            
            
              </MDTypography> <MDTypography variant="h8">
                  <p>
                    {sessionID ? (
                      <span>Você está sincronizado com o SAP  </span>
                    ) : (
                      <span>Liberar SSL e recarregar Session ID...</span>
                    )}
                  </p>

                  <p>
                    {user ? (
                      <span> 
                      Usuário: {user.email}</span>
                    ) : (
                      <span>...</span>
                    )}
                  </p>

                 
            <MDBox p={2}>

                {/*
                  <MDButton variant="gradient" color="dark" onClick={openDialog}>
                    Trocar a base
                  </MDButton>*/}
                    </MDBox> 

                     {/* Conditionally render the logo based on sessionID */}

                 
     
                </MDTypography>


                <Grid container spacing={3} >

                <Grid item  xs={12} lg={8}>
            <MDButton sx={{ width: "350px", height: "40px" }} variant="gradient" color="warning" onClick={downloadlogtutorial} >
              <Icon sx={{ fontWeight: "bold" }}>download</Icon>
              &nbsp;Tutorial p/ apontamentos v0
            </MDButton>
          </Grid>


          
          <Grid item  xs={12} lg={8}>
            <MDButton sx={{ width: "350px", height: "40px" }} variant="gradient" color="warning" onClick={downloadapptutorial} >
              <Icon sx={{ fontWeight: "bold" }}>download</Icon>
              &nbsp;Tutorial de instalação mobile
            </MDButton>
          
          </Grid>  



          </Grid>  

              </MDBox>


              
            </Card>

           
          </Grid>



        

        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Selecionar Base do SAP</DialogTitle>
        <DialogContent>
          Para apontamentos utilizar Base Teste
          <MDBox pt={6} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <Select
                    value={companyDB}
                    onChange={handleCompanyDBChange}
                  >
                   {/* <MenuItem value="SBO_GREYLOGIX_PRD_291222">Base Teste</MenuItem> */}
                    <MenuItem value="SBO_GREYLOGIX_PRD">Base Produção</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </MDBox>

          <MDButton variant="gradient" color="dark" onClick={closeDialog}>
            Cancel
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={fetchSessionID}>
            OK
          </MDButton>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default SessionID;