import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  TextField,
} from '@mui/material';

import { fetchItems, fetchBatches } from './fetchData';
import Cookies from 'js-cookie';
import BatchFormTable from './formtable';

let debounceTimer;
function debounce(func, delay) {
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

const TransferSubForm = ({ line, warehouse, onSubmit, onClose, onDelete, open }) => {

  const [searchCode, setSearchCode] = useState('');
  const [item, setItem] = useState(null);
  const [values, setValues] = useState([]);

  const [items, setItems] = useState([]);
  const [batches, setBatches] = useState([]);

  const [fetchingItems, setFetchingItems] = useState(false);
  const [fetchingBatches, setFetchingBatches] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  useEffect(debounce(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingItems(true);
    fetchItems(
      sessionID,
      searchCode
    ).then((data) => {
      if (data != null) {
        setItems(data);
      }
    }).finally(() => {
      if (!debounceTimer) {
        setFetchingItems(false);
      }
    });
  }, 1000), [searchCode]);

  useEffect(() => {
    if (item !== null && warehouse !== null) {
      const sessionID = Cookies.get('sessionID');
      setFetchingBatches(true);
      fetchBatches(sessionID, item.value, warehouse.value)
        .then((data) => data.value)
        .then((data) => {
          if (data != null) {
            setBatches(data.sort((a, b) => a['Quantity'] === 0 ? 1 : b['Quantity'] === 0 ? -1 : 0));
          }
        }).finally(() => setFetchingBatches(false));
    }
  }, [item, warehouse]);

  useEffect(() => {
    setValues(batches.map(() => 0));
  }, [batches]);

  const handleChangeSearchCode = (e) => {
    setSearchCode(e.target.value);
  }

  const handleChangeItem = (e, value) => {
    setItem(value);
  }

  const handleChangeValue = (index, value) => {
    if (value === false) {
      setValues(values.map((v, i) => i === index ? 0 : v));
    } else if (value === true) {
      const qtd = batches[index]['Quantity'];
      setValues(values.map((v, i) => i === index ? qtd : v));
    } else {
      value = parseFloat(value);
      if (value > batches[index]['Quantity']) {
        value = batches[index]['Quantity'];
      }
      if (value < 0) {
        value = 0;
      }
      setValues(values.map((v, i) => i === index ? value : v));
    }
  }

  const handleReset = () => {
    setItem(null);
    setValues([]);
    setBatches([]);
  }

  const handleClose = () => {
    handleReset();
    onClose();
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setShouldReset(true);
    onDelete();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const numbers = [];
    let total = 0;
    values.forEach((value, index) => {
      if (value > 0) {
        numbers.push({
          BatchNumber: batches[index]['BatchNum'],
          Quantity: value,
        });
        total += value;
      }
    });
    const data = {
      "ItemCode": item.value,
      "ItemLabel": item.label,
      "Quantity": total,
      "U_EASY_Colab": Cookies.get('logemployeeID'),
      "BatchNumbers": numbers,
    }
    handleReset();
    onSubmit(data);
  }

  return (
    <Dialog
      open={open} onClose={handleClose}
      maxWidth="md" fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleReset,
        onSubmit: handleSubmit,
        id: 'transfer-item-form'
      }}
    >
      <DialogTitle>Transferir Material</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item sm={12}>
            <Autocomplete
              fullWidth
              label="Item"
              loading={fetchingItems}
              loadingText="Carregando..."
              options={fetchingItems ? [] : items}
              noOptionsText="Nenhum item encontrado"
              onChange={handleChangeItem}
              onChangeCapture={handleChangeSearchCode}
              renderInput={(params) => <TextField {...params} label="Item" placeholder="Digite o código" />}
              value={item}
            />
          </Grid>
          <Grid item sm={12}>
            <BatchFormTable
              batches={batches}
              isFetching={fetchingBatches}
              onChange={handleChangeValue}
              values={values}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" sx={{ display: 'none' }} onClick={handleDelete} variant="contained">Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" onClick={handleClose}>Cancelar</Button>
        <Button color="warning" variant="contained" type="submit">Transferir</Button>
      </DialogActions>
    </Dialog>
  );
};

TransferSubForm.defaultProps = {
  line: null,
  warehouse: null,
  onSubmit: () => { },
  open: false,
  onClose: () => { },
};

export default TransferSubForm;
