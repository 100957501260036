import React, { Component } from 'react';
import $ from 'jquery';

import LogModal from 'react-modal';
import * as XLSX from 'xlsx';

import Modal from 'react-modal';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import {  Dialog,  DialogTitle,  DialogContent,  TextField,} from '@mui/material';


class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductItemData: [],
      currentPage: 1,
      isLoading: false,
      showAddForm: false,
      
      PlanningSystem:'',
      ProcurementMethod:'',

      fileInputAddRef: null,
      fileInputChangeRef: null,
      


      formData: {
        ItemCode: '',
        ItemName: '',
        ItemType:'itItems',
        ItemsGroupCode: 0,
        Manufacturer: 0,
        NCMCode: 0,
        ItemClass: "itcMaterial",
        MaterialType: "mt_FinishedGoods",

      },
      
      showImportProgressPopup: false,

      failedItemCodes: [],
        // ... other state variables
        importProgress: {
          totalRows: 0, // Total number of rows to import
          rowsProcessed: 0, // Number of rows processed
          failedRequests: 0, // Number of failed requests
        },
    }
  }


  openImportProgressPopup = () => {
    this.setState({ showImportProgressModal: true });
    


  };
  
  closeImportProgressPopup = () => {
    this.setState({ showImportProgressModal: false });
    
    this.handleRefreshButton();


  };


  updateImportProgress = (isSuccessful, ItemCode) => {

    this.setState((prevState) => ({
      importProgress: {
        totalRows: prevState.importProgress.totalRows,
        rowsProcessed: prevState.importProgress.rowsProcessed + 1,
        failedRequests: isSuccessful
          ? prevState.importProgress.failedRequests
          : prevState.importProgress.failedRequests + 1,
      },
      failedItemCodes: isSuccessful
        ? prevState.failedItemCodes
        : [...prevState.failedItemCodes, ItemCode],
    }));
  };
  

  handleRefreshButton = (e) => {
  

    this.setState(
      { ProductItemData: [], currentPage: 1 }, // Clear previous data and reset page
      () => {
        console.log(this.state.currentPage);
        this.getProductItemData();
      }
    );
  };

  handleImportButtonClick = () => {
    if (this.fileInputImport) {
      this.fileInputImport.click(); // Trigger import input
    }
  };

  handleImportButtonClickUpdate = () => {
    if (this.fileInputUpdate) {
      this.fileInputUpdate.click(); // Trigger import input
    }
  };

  addProductItem = () => {
    this.setState({
      showAddForm: true,
      formData: {ItemType:'itItems',
      ItemsGroupCode: 0,
      Manufacturer: 0,
      NCMCode: 0,
      ItemClass: "itcMaterial",
      MaterialType: "mt_FinishedGoods"},
      
    });
  };

  closeAddProductItem = () => {
    this.setState({
      showAddForm: false,
      
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  componentDidMount() {
    // Set app element for react-modal (must be done once)
    console.log('ProductItemComponent mounted');
    LogModal.setAppElement('#app'); // Replace '#root' with the appropriate element
    this.getProductItemData();
  }



  handleAddProductItemSubmit = () => {
    console.log('Submit button clicked');
    const { sessionId } = this.props;

    // bom_Make

    const postData = { 
      ItemName: this.state.formData.ItemName,
      ItemCode: this.state.formData.ItemCode,
      ItemType: this.state.formData.ItemType,
      ItemsGroupCode: parseInt(this.state.formData.ItemsGroupCode),
      Manufacturer: parseInt(this.state.formData.Manufacturer),
      NCMCode: parseInt(this.state.formData.NCMCode),      
      ItemClass: this.state.formData.ItemClass, 
      MaterialType: this.state.formData.MaterialType, 
      ProcurementMethod: this.state.formData.ProcurementMethod || "bom_Buy",
      ForeignName:"null",      
      SalesUnit: "UN",
      SalesPackagingUnit: "UN",
      InventoryUOM: "UN",
      PurchaseUnit: "UN",
      PurchasePackagingUnit: "UN",
      ManageSerialNumbers: "tNO",
      ManageBatchNumbers: "tYES",
      LeadTime:7
      
    };

    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/Items`,
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Request Body:', JSON.stringify(postData, null, 2));
        
  
        this.setState({
          showAddForm: false,
        });

        this.setState({ ProductItemData: [], currentPage: 1 }, () => {
          this.getProductItemData();
        })




      },



     

      error: (error) => {
        console.error('Error:', error);
        console.log('Request Body:', JSON.stringify(postData, null, 2));
      },
    });
  };


  handleFileInputChange = (e) => {
    const file = e.target.files[0];    

    if (!file) {
      return;
    }

    e.target.value = '';
  
    // Use XLSX library to parse the XLSX file
    const reader = new FileReader();

    
    

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assume the sheet name is 'Sheet1'
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Extract data from the sheet
      const rows = XLSX.utils.sheet_to_json(sheet);

      const totalRows = rows.length;

      

      // Iterate through rows and send PATCH requests
      rows.forEach((row) => {
        const { ItemCode,ItemName,PlanningSystem,ProcurementMethod,ItemsGroupCode } = row;

        

        // Construct the PATCH request and send it
        this.sendPatchRequest(ItemCode,ItemName,PlanningSystem,ProcurementMethod,ItemsGroupCode);
      });

      this.setState({
        importProgress: {
          totalRows: totalRows,
          rowsProcessed: 0,
          failedRequests: 0,
        },
        failedItemCodes: [],
      });


    };
    this.openImportProgressPopup();
    reader.readAsArrayBuffer(file);
  };


  handleFileInputAdd = (e) => {
    const file = e.target.files[0];

    

    if (!file) {
      return;
    }

    // Use XLSX library to parse the XLSX file
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assume the sheet name is 'Sheet1'
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Extract data from the sheet
      const rows = XLSX.utils.sheet_to_json(sheet);

      // Iterate through rows and send post requests
      rows.forEach((row) => {
        const { ItemName, ItemCode, ItemType,ItemsGroupCode,Manufacturer,NCMCode,ItemClass,MaterialType,ProcurementMethod } = row;

        // Construct the post request and send it
        this.sendPostRequest(ItemName, ItemCode, ItemType,ItemsGroupCode,Manufacturer,NCMCode,ItemClass,MaterialType,ProcurementMethod);
      });
    };

    reader.readAsArrayBuffer(file);
  };



  sendPostRequest = (ItemName, ItemCode, ItemType,ItemsGroupCode,Manufacturer,NCMCode,ItemClass,MaterialType,ProcurementMethod=null) => {
    const { sessionId } = this.props;

  
    // Construct the postData object with the necessary fields
    const postData = {      
      ItemName: ItemName,
      ItemCode: ItemCode,
      ItemType: ItemType,
      ItemsGroupCode: parseInt(ItemsGroupCode),
      Manufacturer: parseInt(Manufacturer),
      NCMCode: parseInt(NCMCode),      
      ItemClass: ItemClass, 
      MaterialType: MaterialType, 
      ProcurementMethod: ProcurementMethod || "bom_Buy",
      ForeignName:"null",      
      SalesUnit: "UN",
      SalesPackagingUnit: "UN",
      InventoryUOM: "UN",
      PurchaseUnit: "UN",
      PurchasePackagingUnit: "UN",
      ManageSerialNumbers: "tNO",
      ManageBatchNumbers: "tYES",
      LeadTime:7
    };
  
    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/Items`,
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Sucess:', JSON.stringify(postData, null, 2));
        // You can handle the success response here
      },
      error: (error) => {
        console.log('ERROR:', JSON.stringify(postData, null, 2));
        // You can handle the error here
      },
    });
  };


  sendPatchRequest = (ItemCode, ItemName,PlanningSystem,ProcurementMethod,ItemsGroupCode) => {
    const { sessionId } = this.props;
  
    // Construct the patchData object with the necessary fields
    const patchData = {
      ItemName: ItemName,
      PlanningSystem:PlanningSystem,
      ProcurementMethod:ProcurementMethod,
      ItemsGroupCode: parseInt(ItemsGroupCode)
    };
  
    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/Items('${ItemCode}')`,
      type: 'PATCH',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(patchData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        this.updateImportProgress(true);
        console.log('totalrows',this.state.totalRows);
      },
      error: (error) => {
        console.error(`Error updating ItemCode: ${ItemCode}`, error);
        console.log('Request Body:', JSON.stringify(patchData, null, 2));
        // You can handle the error here
        this.updateImportProgress(false, ItemCode);
      },
    });
  };


 
  handleDownloadClick = () => {
    // Use the public URL for your template file
    const templateUrl = process.env.PUBLIC_URL + '/updateitem.xlsx';
    const link = document.createElement('a');
    link.href = templateUrl;
    link.download = 'updateitem.xlsx'; // Specify the filename for the downloaded file.
    link.click();
  };

  handleDownloadAddClick = () => {
    // Use the public URL for your template file
    const templateUrl = process.env.PUBLIC_URL + '/newitem.xlsx';
    const link = document.createElement('a');
    link.href = templateUrl;
    link.download = 'newitem.xlsx'; // Specify the filename for the downloaded file.
    link.click();
  };

  getProductItemData = () => {
    const { sessionId } = this.props;
    $("#okAlert").fadeOut();
    $("#errorAlert").fadeOut();
    const { currentPage, isLoading } = this.state;
    
    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    const skip = (currentPage - 1) * 20; // Adjust the page size here (20 in this case)
    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/Items?$select=ItemCode,ItemName,ItemType,CreateDate,ItemsGroupCode,MaterialType,NCMCode&$skip=${skip}&$top=10&$orderby=UpdateDate desc`;
    // Construct the headers
    const headers = {
      Authorization: `Bearer ${sessionId}`,
    };
    
    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',
 
      success: (response) => {
        const newData = response.value || [];

        this.setState((prevState) => ({
          ProductItemData: [...prevState.ProductItemData, ...newData],
          isLoading: false,
        }));

        if (newData.length === 20) {
          // If you received 20 records, there might be more, so increment the page
          this.setState(
            (prevState) => ({ currentPage: prevState.currentPage + 1 }),
            this.getProductItemData
          );
        }
      },
      error: (error) => {
        console.error('Error:', error);

        this.setState({ isLoading: false });
      },


    });
  };

  renderTable() {
    const { ProductItemData,isLoading } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }
    
    console.log(this.state.ProductItemData);

    
    if (!ProductItemData  || !ProductItemData.length) {
      return <p>No data available.</p>;
    }

    return (

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Produtos
                </MDTypography>
              </MDBox>            
              <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">              
                    <thead>    
                    <TableRow> 
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >ItemCode</MDTypography></TableCell>      
                    <TableCell align="center" style={{ width: '200px' }}><MDTypography variant="h6" >ItemName</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >ItemType</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >CreateDate</MDTypography></TableCell>     
                    <TableCell align="center" style={{ width: '100px' }}><MDTypography variant="h6" >MaterialType</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '50px' }}><MDTypography variant="h6" >Group</MDTypography></TableCell>
                    <TableCell align="center" style={{ width: '50px' }}><MDTypography variant="h6" >NCMCode</MDTypography></TableCell>
                    </TableRow>               
                    </thead>  
                    <tbody>
                      {ProductItemData.map((item, index)  => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.ItemCode != null ? item.ItemCode : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '200px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.ItemName != null ? item.ItemName : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.ItemType != null ? item.ItemType : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.CreateDate != null ? item.CreateDate: '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.MaterialType != null ? item.MaterialType : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '50px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.ItemsGroupCode != null ? item.ItemsGroupCode : '-'}</MDTypography></TableCell>
                        <TableCell align="center" style={{ width: '50px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{item.NCMCode != null ? item.NCMCode : '-'}</MDTypography></TableCell>
                        </TableRow>
                    ))}   
                    </tbody>
                    </Table>
                </TableContainer>      
            </Card>
          </Grid>
        </Grid>
      </MDBox>
   
    );
    

  }

  render() {
    const { importProgress, showImportProgressModal, formData,showAddForm } = this.state;
    return (

      <DashboardLayout>
        <DashboardNavbar />

              <MDButton variant="gradient" color="warning" onClick={this.handleRefreshButton} >
                <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
                &nbsp; Carregar Itens
              </MDButton>

              <MDButton variant="gradient" color="warning" onClick={() => this.addProductItem()} >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Novo Item
              </MDButton>

              <MDButton variant="gradient" color="warning" onClick={() => this.handleDownloadClick()} >
                <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                &nbsp;Template Atualizar
              </MDButton>


              <input
              type="file"
              accept=".xlsx"
              ref={(input) => (this.fileInputUpdate = input)} // Reference for import
              style={{ display: 'none' }}
              onChange={this.handleFileInputChange}
            />   

            

              <MDButton variant="gradient" color="warning" onClick={() => this.handleImportButtonClickUpdate()}  >
                <Icon sx={{ fontWeight: "bold" }}>publish</Icon>
                &nbsp;Atualizar em massa
              </MDButton>

              <MDButton variant="gradient" color="warning" onClick={() => this.handleDownloadAddClick()} >
                <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                &nbsp;Template Add
              </MDButton>         


              <input
              type="file"
              accept=".xlsx"
              ref={(input) => (this.fileInputImport = input)} // Reference for update
              style={{ display: 'none' }}
              onChange={this.handleFileInputAdd}
            />

              <MDButton variant="gradient" color="warning" onClick={() => this.handleImportButtonClick()} >
                <Icon sx={{ fontWeight: "bold" }}>publish</Icon>
                &nbsp;Add em massa
              </MDButton>

         
        {this.renderTable()}


        <Modal
        isOpen={showImportProgressModal}
        onRequestClose={this.closeImportProgressPopup}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Import Progress: {importProgress.rowsProcessed} / {importProgress.totalRows}</h2>
        <div>
          Failed Requests: {importProgress.failedRequests}
        </div>
        
        {importProgress.failedRequests > 0 && (
          <div>
            Failed ItemCodes: {this.state.failedItemCodes.join(', ')}
          </div>
        )}
        <button className="button" onClick={this.closeImportProgressPopup}>Close</button>
      </Modal>
  
        

        <Dialog  open={showAddForm} onClose={this.closeAddProductItem}>
            <DialogTitle>Adicionar Produto</DialogTitle>
            <DialogContent>         
            <MDBox pt={6} pb={3}>
              <Grid container spacing={2}>                
                <Grid item xs={12}>
                  <TextField
                    label="ItemName"
                    name="ItemName"
                    value={formData.ItemName || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="ItemCode"
                    name="ItemCode"
                    value={formData.ItemCode || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="ItemsGroupCode"
                    name="ItemsGroupCode"
                    value={formData.ItemsGroupCode|| 0}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Manufacturer"
                    name="Manufacturer"
                    value={formData.Manufacturer || 0}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>    
                <Grid item xs={12}>
                  <TextField
                    label="NCMCode"
                    name="NCMCode"
                    value={formData.NCMCode || 0}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>   
                <Grid item xs={12}>
                  <TextField
                    label="ItemClass"//"itcMaterial"
                    name="ItemClass"
                    value={formData.ItemClass || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>   
                <Grid item xs={12}>
                  <TextField
                    label="MaterialType"//"mt_FinishedGoods"
                    name="MaterialType"
                    value={formData.MaterialType || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>   
                <Grid item xs={12}>
                  <TextField
                    label="ItemType"//"itItems"
                    name="ItemType"
                    value={formData.ItemType || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>               
                
              </Grid>
              </MDBox>
              <MDButton variant="gradient" color="warning" onClick={this.handleAddProductItemSubmit}>
                Submeter
              </MDButton>
              <MDButton variant="gradient" color="warning" onClick={this.closeAddProductItem}>
                Cancelar
              </MDButton>             

            </DialogContent>
          </Dialog>
      

        <Footer />
      </DashboardLayout>
    );
  }
  
}

export default Product;


