import React, { useEffect, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  TextField,
} from '@mui/material';

import { fetchBatches } from './fetchData';
import Cookies from 'js-cookie';
import BatchFormTable from './formtable';


const TransferSubForm = ({ line, onSubmit, onClose, onDelete, open }) => {

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [whs, setWhs] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [mu, setMu] = useState('');
  const [batches, setBatches] = useState([]);
  const [values, setValues] = useState([]);
  const [remaining, setRemaining] = useState(0);

  const [fetchingBatches, setFetchingBatches] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  useEffect(() => {
    if (line) {
      setName(line['ItemDescription']);
      setQuantity(line['RemainingOpenQuantity']);
      setCode(line['ItemCode']);
      setWhs(line['FromWarehouseCode']);
      setRemaining(line['RemainingOpenQuantity']);
      setMu(line['MeasureUnit']);
      setFetchingBatches(true);
      const sessionID = Cookies.get('sessionID');
      fetchBatches(sessionID, line['ItemCode'], line['FromWarehouseCode'])
      .then((data) => data.value)
      .then((data) => {
        if (data != null) {
          setBatches(data.sort((a, b) => a['Quantity'] === 0 ? 1 : b['Quantity'] === 0 ? -1 : 0));
        }
      }).finally(() => setFetchingBatches(false));
    } else if (shouldReset) {
      setShouldReset(false);
      handleReset();
    }
  }, [line]);

  useEffect(() => {
    setValues(batches.map(() => 0));
    setRemaining(quantity);
  }, [batches]);

  useEffect(() => {
    setRemaining(quantity - values.reduce((a, b) => a + b, 0));
  }, [values]);

  const handleChangeValue = (index, value) => {
    if (value === false) {
      setValues(values.map((v, i) => i === index ? 0 : v));
    } else if (value === true) {
      const qtd = batches[index]['Quantity'] > remaining ? values[index] + remaining : batches[index]['Quantity'];
      setValues(values.map((v, i) => i === index ? qtd : v));
    } else {
      value = parseFloat(value);
      if (value > batches[index]['Quantity']) {
        value = batches[index]['Quantity'];
      }
      if (value < 0) {
        value = 0;
      }
      setValues(values.map((v, i) => i === index ? value : v));
    }
  }

  const handleReset = () => {
    setBatches([]);
    setValues([]);
    setRemaining(0);
    setName('');
    setCode('');
    setWhs('');
    setQuantity(0);
    setMu('');
  }

  const handleClose = () => {
    handleReset();
    onClose();
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setShouldReset(true);
    onDelete();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShouldReset(true);
    const data = {
      "BaseType": "InventoryTransferRequest",
      "BaseLine": line['LineNum'],
      "BaseEntry": line['DocEntry'],
      "Quantity": quantity - remaining,
      "ItemCode": code,
      "U_EASY_Colab": Cookies.get('logemployeeID'),
      "BatchNumbers": values.map((v, i) => v > 0 ? {
        "BatchNumber": batches[i]['BatchNum'],
        "Quantity": v,
      } : undefined),
    };
    onSubmit(data);
  }

  return (
    <Dialog
      open={open} onClose={handleClose}
      maxWidth="md" fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleReset,
        onSubmit: handleSubmit,
        id: 'project-stage-form'
      }}
    >
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item sm={4}>
            <TextField fullWidth label="Item" value={code} disabled variant="standard" />
          </Grid>
          <Grid item sm={2}>
            <TextField fullWidth label="Depósito" value={whs} disabled variant="standard" />
          </Grid>
          <Grid item sm={2}>
            <TextField fullWidth label="Unidade Medida" value={mu} disabled variant="standard" />
          </Grid>
          <Grid item sm={4}>
            <TextField fullWidth label="Quantidade para Transferir" value={remaining} disabled variant="standard" />
          </Grid>
          <Grid item xs={12}>
          <BatchFormTable
            batches={batches}
            isFetching={fetchingBatches}
            isMaxed={remaining == 0}
            onChange={handleChangeValue}
            values={values}
          />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" sx={{ display: 'none' }} onClick={handleDelete} variant="contained">Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" onClick={handleClose}>Cancelar</Button>
        <Button color="warning" disabled={quantity - remaining == 0} variant="contained" type="submit">Transferir</Button>
      </DialogActions>
    </Dialog>
  );
};

TransferSubForm.defaultProps = {
  line: null,
  onSubmit: () => { },
  open: false,
  onClose: () => { },
};

export default TransferSubForm;
