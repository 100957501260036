// src/firebase/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = initializeApp({
  apiKey: "AIzaSyDDub5iNgDjJMYkPjVCoGS_ksakgQdxgqI",
  authDomain: "greylogix-4200.firebaseapp.com",
  projectId: "greylogix-4200",
  storageBucket: "greylogix-4200.appspot.com",
  messagingSenderId: "245172095949",
  appId: "1:245172095949:web:3756f80c6eb10696711284",
  measurementId: "G-NEGTGGD08W"
});


const auth = getAuth(app);


export { auth };