import React, { memo, useCallback } from 'react';
import {
  CircularProgress, IconButton, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { Check, Close, Edit } from '@mui/icons-material';

import { roles } from './fetchData';

const EmployeeTable = memo(function EmployeeTable({ data, isLoading, onEdit }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data, onEdit]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Sobrenome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Cargo</TableCell>
            <TableCell>Centro de Custo</TableCell>
            <TableCell>Permissão</TableCell>
            <TableCell>Lote</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={12} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Edit /></IconButton></TableCell>
              <TableCell>{item['EmployeeID']}</TableCell>
              <TableCell>{item['EmployeeCode']}</TableCell>
              <TableCell>{item['FirstName']}</TableCell>
              <TableCell>{item['LastName']}</TableCell>
              <TableCell>{item['eMail']}</TableCell>
              <TableCell>{item['JobTitle']}</TableCell>
              <TableCell>{item['CostCenterCode']}</TableCell>
              <TableCell>{roles.find((i) => i.value === item['U_Portal_Role'])?.label || '-'}</TableCell>
              <TableCell>{item['U_EASY_ApontaEmLote'] === 'Y' && <Check color="success" />}</TableCell>
              <TableCell>{item['Active'] === 'tYES' ? <Check color="success" /> : <Close color="error" />}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={12} align="center">
                Nenhum colaborador encontrado
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default EmployeeTable;
