import React, { Component } from 'react';
import $ from 'jquery';

import LogModal from 'react-modal';

import { Button, Container } from '@mui/material';
import { Refresh, Add } from '@mui/icons-material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import {  Dialog,  DialogTitle,  DialogContent,  TextField,} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';


class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { item, children } = this.props;
    const { expanded } = this.state;
    

    return (
      <div > 
        <TableRow>
        <div onClick={this.toggleExpanded}>
        <TableCell align="center" style={{ width: '50px' }}>{expanded ? <ExpandLess /> : <ExpandMore />}</TableCell> 
        <TableCell align="left" style={{ width: '800px' }}><MDTypography variant="h6">{item.DocEntry != null ? item.DocEntry : '-'} {'('}{item.DocumentStatus != null ? item.DocumentStatus : '-'}{') '}{item.RequriedDate != null ? item.RequriedDate : '-'}  {'-'}{item.Project != null ? item.Project : '-'}   {'-'}{item.RequesterEmail != null ? item.RequesterEmail : '-'}        </MDTypography>   </TableCell>   
     
        </div>
        {expanded && children}
        </TableRow>
      </div>
    );
  }
}

class PurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PurchaseRequestData: [],
      itemCodeOptions: [],
      currentPage: 1,
      pcurrentPage:1,
      isLoading: false,
      showAddForm: false,
    
      formData: {
        Project: "",
        Comments: "",
        RequriedDate: "",
        RequesterEmail: ""     
      },

      

    products: [
      {
        ItemCode: "",
        Quantity: null,
        WarehouseCode: null,
      },
    ],

    };
  }

 

  getProductItemData = () => {
    const { sessionId } = this.props;
    const { pcurrentPage } = this.state;   
  
    const skip = (pcurrentPage - 1) * 20; // Adjust the page size here (20 in this case)
    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/Items?$select=ItemCode,ItemName,ItemType&$skip=${skip}&$top=10&$orderby=UpdateDate desc`;
    // Construct the headers
    const headers = {
      Authorization: `Bearer ${sessionId}`,
    };
    
    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',
 
      success: (response) => {
        const newData = response.value || [];

        this.setState((prevState) => ({
          itemCodeOptions: [...prevState.itemCodeOptions, ...newData],

        }));

        if (newData.length === 20) {
          // If you received 20 records, there might be more, so increment the page
          this.setState(
            (prevState) => ({ pcurrentPage: prevState.pcurrentPage + 1 }),
            this.getProductItemData
          );
        }
      },
      error: (error) => {
        console.error('Error:', error);

      },


    });
  };

  handleItemCodeFocus = (index) => {
    // Check if the item code options are already fetched
    if (this.state.itemCodeOptions.length === 0) {
      // If not, fetch the options
      this.getProductItemData();
    }
    // Additional logic you might want to add when the ItemCode field is focused
    console.log(`ItemCode field at index ${index} is focused`);
  };


  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log(`Handling change for ${name}, index: ${index}, value: ${value}`);
  
    if (index !== undefined) {
      // Handling changes for product fields
      this.setState((prevState) => {
        const updatedProducts = [...prevState.products];
        updatedProducts[index] = {
          ...updatedProducts[index],
          [name]: value,
        };
        return { products: updatedProducts };
      });
    } else {
      // Handling changes for non-product fields
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }));
    }
    
    // Add this line to log the current state of products after the update
    console.log('Current state of products:', this.state.products);
  };

  handleAutocompleteChange = (value, index) => {
    this.setState((prevState) => {
      const updatedProducts = [...prevState.products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        ItemCode: value?.ItemCode || '', // Update ItemCode with the selected value
      };
      return { products: updatedProducts };
    });
  };


addProduct = () => {
  this.setState((prevState) => ({
    products: [
      ...prevState.products,
      {
        ItemCode: "",
        Quantity: null,
        WarehouseCode: null,
      },
    ],
  }));
};

  handleRefreshButton = (e) => {
    console.log('handleRefreshButton called');

    this.setState(
      { PurchaseRequestData: [], currentPage: 1 }, // Clear previous data and reset page
      () => {
        console.log(this.state.currentPage);
        this.getPurchaseRequestData();
      }
    );
  };

  addPurchaseRequest = () => {
    this.setState({
      showAddForm: true,
      formData: {
        ItemCode: "",
        Project: "",
        Comments: "",
        RequriedDate: "2023-01-01",
        WarehouseCode: "20.2",
        Quantity: "", 
        RequesterEmail: "x@greylogix.com.br"       
      },
      products: [
        {
          ItemCode: "",
          Quantity: "",
          WarehouseCode: "",
        },
      ],
    });
  };

  closeAddPurchaseRequest = () => {
    this.setState({
      showAddForm: false,
    });
  };

  componentDidMount() {
    // Set app element for react-modal (must be done once)
    console.log('PurchaseRequestComponent mounted');
    LogModal.setAppElement('#app'); // Replace '#root' with the appropriate element
    this.getPurchaseRequestData();
  }

  handleAddPurchaseRequestSubmit = () => {
    console.log('Submit button clicked');
    const { sessionId } = this.props;

    


    const postData = {
      DocumentLines: this.state.products.map((product) => ({
        ItemCode: product.ItemCode,
        Quantity: product.Quantity,
        WarehouseCode: product.WarehouseCode,
      })),
      RequriedDate: this.state.formData.RequriedDate,
      BPL_IDAssignedToInvoice: 3,
      Project: this.state.formData.Project,
      Comments: this.state.formData.Comments,
      RequesterEmail: this.state.formData.RequesterEmail,
    };

   

    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseRequests`,
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Request Body:', JSON.stringify(postData, null, 2));

        this.setState({
          showAddForm: false,
        });

        this.setState({ PurchaseRequestData: [], currentPage: 1 }, () => {
          this.getPurchaseRequestData();
        })
      },
      error: (error) => {
        console.error('Error:', error);
        console.log('Request Body:', JSON.stringify(postData, null, 2));
      },
    });
  };

  getPurchaseRequestData = () => {
    const { sessionId } = this.props;
    $("#okAlert").fadeOut();
    $("#errorAlert").fadeOut();
    const { currentPage, isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    const skip = (currentPage - 1) * 20; // Adjust the page size here (20 in this case)
    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseRequests?$skip=${skip}`;
    // Construct the headers
    const headers = {
      Authorization: `Bearer ${sessionId}`,
    };

    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',

      success: (response) => {
        const newData = response.value || [];

        this.setState((prevState) => ({
          PurchaseRequestData: [...prevState.PurchaseRequestData, ...newData],
          isLoading: false,
        }));

        if (newData.length === 20) {
          // If you received 20 records, there might be more, so increment the page
          this.setState(
            (prevState) => ({ currentPage: prevState.currentPage + 1 }),
            this.getPurchaseRequestData
          );
        }
      },
      error: (error) => {
        console.error('Error:', error);

        this.setState({ isLoading: false });
      },
    });
  };

  renderTree() {
    const { PurchaseRequestData, isLoading } = this.state;
  
    if (isLoading) {
      return <p>Loading...</p>;
    }
  

    return (


      
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projetos
                </MDTypography>
              </MDBox>            

     
       
        <tr>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ItemCode</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '300px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ItemDescription</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '150px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >Quantity</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '170px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >ShipDate</MDTypography></TableCell>
          </tr>
  
        {PurchaseRequestData.map((item, parentIndex) => (
          <TreeNode key={parentIndex} item={item}>
            {item.DocumentLines.map((line, lineIndex) => (
              <tr  key={`${parentIndex}_${lineIndex}`}>
              
                <TableCell align="center" style={{ width: '100px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemCode != null ? line.ItemCode : '-'}</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '300px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemDescription != null ? line.ItemDescription : '-'}</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '150px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.Quantity != null ? line.Quantity : '-'}</MDTypography></TableCell>
                <TableCell align="center" style={{ width: '170px' }}><MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ShipDate != null ? line.ShipDate : '-'}</MDTypography></TableCell>
               
              </tr>
              
            ))}
          </TreeNode>
        ))}
   
      </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }



  render() {
    const { showAddForm, formData, products } = this.state;
    const{itemCodeOptions}= this.state;
    return (


       
      <DashboardLayout>
        <DashboardNavbar />

              <MDButton variant="gradient" color="warning" onClick={this.handleRefreshButton} >
                <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
                &nbsp;Carregar Requisições
              </MDButton>
              <MDButton variant="gradient" color="warning" 

                    onClick={() => {

                      this.addPurchaseRequest();

                      this.setState({ 
                        itemCodeOptions: [],pcurrentPage:1 
                      }, () => {
                        // This callback will run after ProfitCenterData is set to an empty array
                        this.getProductItemData(); // Call the getProfitCenterData function
                      });                 

                    
                    }}
               >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Adicionar Solicitação
              </MDButton>
    
        {this.renderTree()}

        <Dialog  open={showAddForm} onClose={this.closeAddPurchaseRequest}>
            <DialogTitle>Nova Solicitação</DialogTitle>
            <DialogContent>         
            <MDBox pt={6} pb={3}>
              <Grid container spacing={2}>   

              <Grid item xs={12}>
                  <TextField
                    label="Project"
                    name="Project"
                    value={formData.Project || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>
               
                <Grid item xs={12}>
                  <TextField
                    label="Comments"
                    name="Comments"
                    value={formData.Comments || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    label="RequriedDate"
                    name="RequriedDate"
                    value={formData.RequriedDate || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>   

                <Grid item xs={12}>
                  <TextField
                    label="RequesterEmail"
                    name="RequesterEmail"
                    value={formData.RequesterEmail || ''}
                    onChange={this.handleInputChange}
                    fullWidth
                  />
                </Grid>       
                
                

                      {this.state.products.map((product, index) => (
                      <div key={index}>
                    <MDBox pt={1} pb={1}  mx={2} >

                    <Grid item xs={12}>
                    <Autocomplete
                    disablePortal
                    options={itemCodeOptions}
                    getOptionLabel={(option) => option.ItemCode.toString() + ' - ' + option.ItemName.substring(0, 10)}
                    onChange={(e, value) => this.handleAutocompleteChange(value, index)}
                    onFocus={() => this.handleItemCodeFocus(index)}
                    renderInput={(params) => <TextField {...params} label={`ItemCode ${index + 1}`} />}
                  />

                  </Grid>            

                  {/*  <Grid item xs={12}>
                        <TextField
                          label={`ItemCode ${index + 1}`}
                          name={`ItemCode`}
                          value={product.ItemCode || ''}
                          onChange={(e) => this.handleInputChange(e, index)}
                          fullWidth
                      /> 
                     </Grid>   */}
                      <Grid item xs={12}>
                        <TextField
                          label={`Quantity ${index + 1}`}
                          name={`Quantity`}
                          value={product.Quantity || ''}
                          onChange={(e) => this.handleInputChange(e, index)}
                          fullWidth
                        />
                     </Grid>  
                      <Grid item xs={12}>
                        <TextField
                          label={`WarehouseCode ${index + 1}`}
                          name={`WarehouseCode`}
                          value={product.WarehouseCode || ''}
                          onChange={(e) => this.handleInputChange(e, index)}
                          fullWidth
                        />

                      </Grid>  

                      </MDBox>  
                      </div>
                    ))}
                    <Button onClick={this.addProduct}>Add Product</Button>  

                    
                                
                                    
              </Grid>
              </MDBox>
              <MDButton variant="gradient" color="warning" onClick={this.handleAddPurchaseRequestSubmit}>
                Submeter
              </MDButton>
              <MDButton variant="gradient" color="warning" onClick={this.closeAddPurchaseRequest}>
                Cancelar
              </MDButton>             

            </DialogContent>
          </Dialog> 

        <Footer/>
</DashboardLayout >
    );
  }
}

export default PurchaseRequest;

