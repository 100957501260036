import { useEffect, useState } from "react";
import { Add, Check, Close, Edit, LabelOutlined } from "@mui/icons-material";
import { Autocomplete, Button, ButtonGroup, FormControl, IconButton, InputAdornment, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { activities } from "layouts/TimeSheet/fetchData";
import Cookies from "js-cookie";

const StagesTable = ({ stages, onAdd, onEdit }) => {

  const handleAdd = (e) => {
    e.preventDefault();
    onAdd();
  }

  const handleEdit = (id) => {
    onEdit(id);
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Button color="warning" onClick={handleAdd} size="small" sx={{ minWidth: 0 }} variant="contained">
              <Add />
            </Button>
          </TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Data Início</TableCell>
          <TableCell>Data Fluxo</TableCell>
          <TableCell>Data Faturamento</TableCell>
          <TableCell>Etapa do Projeto</TableCell>
          <TableCell>Descrição</TableCell>
          <TableCell>Orçado</TableCell>
          <TableCell>Planejado</TableCell>
          <TableCell>Pedido de Compra</TableCell>
          <TableCell>Valor Recebido</TableCell>
          <TableCell>Saldo para Compras</TableCell>
          <TableCell>Savings</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'auto' }}>
        <RenderStagesRows stages={stages} onEdit={handleEdit} />
      </TableBody>
    </Table>
  );
};

function RenderStagesRows({ stages, onEdit }) {
  const rows = [];
  const totals = {
    'U_BaseCost': 0,
    'ExpectedCosts': 0,
    'TotalPurchase': 0,
    'TotalInvoice': 0,
    'Saldo': 0,
    'Savings': 0,
  };
  for (let i = 0; i < stages.length; i++) {
    if (stages[i]['StageType'] !== 6) {
      continue;
    }
    stages[i]['Savings'] = stages[i]['ExpectedCosts'] - stages[i]['TotalPurchase'];
    stages[i]['Saldo'] = stages[i]['U_BaseCost'] - stages[i]['TotalPurchase'];
    if (stages[i]['Savings'] < 0 || stages[i]['TotalInvoice'] <= 0) {
      stages[i]['Savings'] = 0;
    }
    totals['U_BaseCost'] += stages[i]['U_BaseCost'];
    totals['ExpectedCosts'] += stages[i]['ExpectedCosts'];
    totals['TotalPurchase'] += stages[i]['TotalPurchase'];
    totals['TotalInvoice'] += stages[i]['TotalInvoice'];
    totals['Saldo'] += stages[i]['Saldo'];
    totals['Savings'] += stages[i]['Savings'];
    rows.push(
      <TableRowComponent
        index={i}
        key={i}
        line={stages[i]}
        onEdit={onEdit}
      />
    );
  }
  rows.push(
    <TableRow key={stages.length}>
      <TableCell colSpan={7} />
      <TableCell variant="footer" >{totals['U_BaseCost'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell variant="footer" >{totals['ExpectedCosts'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell variant="footer" >{totals['TotalPurchase'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell variant="footer" >{totals['TotalInvoice'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell variant="footer" >{totals['Saldo'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell variant="footer" >{totals['Savings'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
    </TableRow>
  );
  return rows;
}

const highlight = Cookies.get('darkMode') === 'true' ? '#515151' : '#F1F1F1';

function TableRowComponent({ line, index, onEdit }) {

  const handleEdit = (e) => {
    e.preventDefault();
    onEdit(index);
  }

  return (
    <TableRow sx={{
      backgroundColor: index % 2 > 0 ? highlight : 'inherit',
      backgroundBlendMode: 'darken',
      backgroundImage: (line['Saldo'] < 0) || (line['Savings'] < 0) ? 'linear-gradient(#F002, #F002)' : 'inherit',
    }}>
      <TableCell align="center">
        <IconButton onClick={handleEdit} size="small"><Edit /></IconButton>
      </TableCell>
      <TableCell>{line['StageID']}</TableCell>
      <TableCell>{dayjs(line['StartDate']).format('DD/MM/YYYY')}</TableCell>
      <TableCell>{line['CloseDate'] ? dayjs(line['CloseDate']).format('DD/MM/YYYY') : '-'}</TableCell>
      <TableCell>{line['FinishedDate'] ? dayjs(line['FinishedDate']).format('DD/MM/YYYY') : '-'}</TableCell>
      <TableCell>{line['label']}</TableCell>
      <TableCell>{line['Description']}</TableCell>
      <TableCell>{line['U_BaseCost'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell>{line['ExpectedCosts'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell>{line['TotalPurchase']?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell>{line['TotalInvoice']?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell sx={line['Saldo'] < 0 && { color: 'red' }}>{(
        line['Saldo']
      ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell sx={line['Savings'] < 0 && { color: 'red' }}>{(
        line['Savings']
      ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
    </TableRow>
  );
}

export default StagesTable;
