import { Add, Edit } from "@mui/icons-material";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import Cookies from "js-cookie";


const StagesTable = ({ stages, onAdd, onEdit }) => {

  const handleAdd = (e) => {
    e.preventDefault();
    onAdd();
  }

  const handleEdit = (id) => {
    onEdit(id);
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Button color="warning" onClick={handleAdd} size="small" sx={{ minWidth: 0 }} variant="contained">
              <Add />
            </Button>
          </TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Data Início</TableCell>
          <TableCell>Data Fim</TableCell>
          <TableCell>Data Final</TableCell>
          <TableCell>Descrição</TableCell>
          <TableCell>Tempo Planejado</TableCell>
          <TableCell>Tempo Realizado</TableCell>
          <TableCell>Previsto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'auto' }}>
        {stages.map((stage, index) => {
          if (stage['StageType'] !== 3) {
            return null;
          }
          return (
            <TableRowComponent
              index={index}
              key={index}
              line={stage}
              onEdit={handleEdit}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const highlight = Cookies.get('darkMode') === 'true' ? '#515151' : '#F1F1F1';

function TableRowComponent({ line, index, onEdit }) {

  const handleEdit = (e) => {
    e.preventDefault();
    onEdit(index);
  }

  const saldo = line['U_EASY_TempoPlanejado'] - line['U_EASY_TempoPlanejado'];

  return (
    <TableRow sx={{
      backgroundColor: index % 2 > 0 ? highlight : 'inherit',
      backgroundBlendMode: 'darken',
      backgroundImage: saldo < 0 ? 'linear-gradient(#F002, #F002)' : 'inherit',
    }}>
      <TableCell align="center">
        <IconButton onClick={handleEdit} size="small"><Edit /></IconButton>
      </TableCell>
      <TableCell>{line['StageID']}</TableCell>
      <TableCell>{dayjs(line['StartDate']).format('DD/MM/YYYY')}</TableCell>
      <TableCell>{line['CloseDate'] ? dayjs(line['CloseDate']).format('DD/MM/YYYY') : '-'}</TableCell>
      <TableCell>{line['FinishedDate'] ? dayjs(line['FinishedDate']).format('DD/MM/YYYY') : '-'}</TableCell>
      <TableCell>{line['Description']}</TableCell>
      <TableCell>{line['U_EASY_TempoPlanejado']}</TableCell>
      <TableCell>{line['U_EASY_TempoPlanejado']}</TableCell>
      <TableCell>{line['ExpectedCosts'].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
    </TableRow>
  );
}

export default StagesTable;
