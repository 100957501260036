import React, { memo, useCallback } from 'react';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';

const StockTransfersTable = memo(function StockTransfersTable({ data, isLoading, onEdit }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Colaborador</TableCell>
            <TableCell>Soli.</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>De</TableCell>
            <TableCell>Para</TableCell>
            <TableCell>Qtd</TableCell>
            <TableCell>Comentários</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.id}
              </TableCell>
              <TableCell>{item.employee}</TableCell>
              <TableCell>{item.BaseEntry}</TableCell>
              <TableCell>{item.date}</TableCell>
              <TableCell>{item.ItemCode}</TableCell>
              <TableCell>{item.FromWarehouseCode}</TableCell>
              <TableCell>{item.WarehouseCode}</TableCell>
              <TableCell>{item.Quantity}</TableCell>
              <TableCell>{item.Comments}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Nenhuma transferência encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default StockTransfersTable;
