import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase";
import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import Cookies from 'js-cookie'; // Import the js-cookie library

const useAuth = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const signInWithMicrosoft = async () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: "select_account"
    });
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      getEmail(result.user);
      const useremail = getEmail(result.user); // Get the user email
      Cookies.set(
        'useremail',
        useremail,
        { expires: 7, sameSite: 'strict' }
      ); // Save user email in a cookie
      navigate('/Home');
    } catch (error) {
      alert(`Erro ao entrar com Microsoft: ${error.message}`);
      console.error("Erro ao entrar com Microsoft", error);
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Erro ao sair", error);
    }
  };

  const getEmail = (user) => {
    if (user) {
      console.log(user.email);
      return user.email;
    }
    return null;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authenticatedUser) => {
      if (authenticatedUser) {
        setUser(authenticatedUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return {
    user,
    signInWithMicrosoft,
    signOut,
    getEmail
  };
};

export default useAuth;
