/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { NavLink } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import useAuth from "../../../hooks/useAuth";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import useMediaQuery from '@mui/material/useMediaQuery'; // Import useMediaQuery from Material-UI

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import logoImage from "assets/images/logo-laranja.png";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const { signInWithMicrosoft } = useAuth();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust the max-width as needed

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async (event) => {
    event.preventDefault();
    await signInWithMicrosoft();
  };

  const handleDownloadClick = () => {
    const templateUrl = process.env.PUBLIC_URL + '/manifest.json';
    const link = document.createElement('a');
    link.href = templateUrl;
    link.download = 'manifest.json';
    link.click();
  };

  return (
    <BasicLayout image={bgImage}>
      <MDBox
        component="form"
        role="form"
        onSubmit={handleSignIn}
        style={{ position: 'relative' }}
      >
        <MDBox mb={2} textAlign="center">
          <img src={logoImage} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
        </MDBox >
        <MDBox mt={4} mb={1} textAlign="center">
          <MDButton sx={{ width: "150px", height: "40px" }} type="submit" variant="gradient" color="info" >
            Login
          </MDButton>
        </MDBox>

{/*
        <MDBox mt={12} mb={1} textAlign="center">
          {isMobile && (
            <MDButton variant="gradient" color="warning" onClick={() => handleDownloadClick()}>
              <Icon sx={{ fontWeight: "bold" }}>download</Icon>
              &nbsp;Baixar app
            </MDButton>
          )}
          </MDBox>  */}
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
