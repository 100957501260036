import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { fetchProductionOrderLines, fetchWarehouses, createInventoryExit } from './fetchData';

const LogMatFormModal = ({ open, onClose, onSubmit, employees, productionOrders, fixedEmployee }) => {

  const [employee, setEmployee] = useState(fixedEmployee);
  const [productionOrder, setProductionOrder] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(null);
  const [productionOrderLines, setProductionOrderLines] = useState([]);
  const [currentLines, setCurrentLines] = useState([]);
  const [date, setDate] = useState(dayjs());

  const [fetchingProductionOrderLines, setFetchingProductionOrderLines] = useState(false);
  const [fetchingWarehouses, setFetchingWarehouses] = useState(false);
  const [saving, setSaving] = useState(false);

  const { toggleStatus } = useStatus();

  useEffect(() => {
    if (fixedEmployee) {
      setEmployee(fixedEmployee);
    }
  }, [fixedEmployee]);

  useEffect(() => {
    if (productionOrder) {
      setFetchingWarehouses(true);
      const sessionID = Cookies.get('sessionID');
      fetchWarehouses(sessionID, productionOrder['AbsoluteEntry']).then((response) => {
        setWarehouses(response);
      }).catch((error) => {
        console.error(error);
        window.alert('Erro ao buscar os depósitos: ' + JSON.stringify(error));
      }).finally(() => {
        setFetchingWarehouses(false);
      });
    }
  }, [productionOrder]);

  useEffect(() => {
    if (productionOrder && warehouse) {
      setFetchingProductionOrderLines(true);
      const sessionID = Cookies.get('sessionID');
      fetchProductionOrderLines(sessionID, productionOrder['AbsoluteEntry'], warehouse?.WarehouseCode).then((response) => {
        setProductionOrderLines(response);
      }).catch((error) => {
        console.error(error);
        window.alert('Erro ao buscar as linhas de produção: ' + JSON.stringify(error));
      }).finally(() => {
        setFetchingProductionOrderLines(false);
      });
    }
  }, [productionOrder, warehouse]);

  useEffect(() => {
    setCurrentLines(productionOrderLines);
  }, [productionOrderLines]);

  const handleChangeEmployee = (e, value) => {
    e.preventDefault();
    setEmployee(value);
  }

  const handleChangeProductionOrder = (e, value) => {
    e.preventDefault();
    setProductionOrder(value);
    setWarehouse(null);
    setProductionOrderLines([]);
    setCurrentLines([]);
  }

  const handleChangeWarehouse = (e, value) => {
    e.preventDefault();
    setWarehouse(value);
    setProductionOrderLines([]);
    setCurrentLines([]);
  }

  const handleChangeProductionOrderLineValue = (index, value) => {
    const newLines = [...productionOrderLines];
    newLines[index]['qtd'] = value;
    setProductionOrderLines(newLines);
  }

  const handleChangeDate = (value) => {
    setDate(value);
  }

  const handleReset = () => {
    setEmployee(null);
    setProductionOrder(null);
    setWarehouse(null);
    setProductionOrderLines([]);
    setCurrentLines([]);
    setDate(dayjs());
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const sessionID = Cookies.get('sessionID');
    const data = {
      EmployeeID: employee.EmployeeID,
      ProductionOrder: productionOrder,
      ProductionOrderLines: productionOrderLines,
      Date: date.format('YYYY-MM-DD'),
    };
    setSaving(true);
    createInventoryExit(sessionID, data).then((response) => {
      console.log(response);
      handleReset();
      onSubmit();
      onClose();
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar o apontamento!', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const isInvalid = () => (
    date === null || productionOrder === null || warehouse === null || !date.isValid()
    || date < dayjs(productionOrder.StartDate) || date > dayjs()
  );

  const linesTable = useMemo(() => (
    <LinesTable lines={currentLines} handleChangeLines={handleChangeProductionOrderLineValue} />
  ), [currentLines]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleCancel,
        onSubmit: handleSubmit,
        id: 'log-prod-form'
      }}
      maxWidth="md"
    >
      <DialogTitle>Apontamento de Consumo de Material</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={fixedEmployee !== null}
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeEmployee}
              options={employees}
              renderInput={(params) => <TextField {...params} label="Colaborador" required />}
              value={employee}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <Autocomplete
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeProductionOrder}
              options={productionOrders}
              renderInput={(params) => <TextField {...params} label="Ordem Produção" required />}
              value={productionOrder}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              disabled={productionOrder === null}
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeWarehouse}
              options={warehouses}
              renderInput={(params) => <TextField {...params} label="Depósito" required />}
              value={warehouse}
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  id="date"
                  label="Data"
                  disabled
                  onChange={handleChangeDate}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  value={date}
                  minDate={dayjs(productionOrder?.StartDate)}
                  disableFuture
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} elevation={3}>
              {linesTable}
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
        <Button
          disabled={isInvalid()}
          color="warning"
          variant="contained"
          size="large"
          type="submit"
        >Salvar</Button>
      </DialogActions>
      <Backdrop
        open={fetchingProductionOrderLines || fetchingWarehouses}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop open={saving} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Alert severity="info">Salvando...</Alert>
      </Backdrop>
    </Dialog>
  );
}

const LinesTable = ({ lines, handleChangeLines }) => {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Linha</TableCell>
          <TableCell>Material</TableCell>
          <TableCell>Apontado</TableCell>
          <TableCell>Planejado</TableCell>
          <TableCell>Disponível</TableCell>
          <TableCell align='center'>Apontar</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((line, index) => (
          <TableRowComponent
            key={index}
            line={line}
            index={index}
            handleChangeLines={handleChangeLines}
          />
        ))}
      </TableBody>
    </Table>
  );
};

function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  }
}

function TableRowComponent({ line, index, handleChangeLines }) {
  const [qtd, setQtd] = React.useState(line['qtd']);

  useEffect(() => {
    setQtd(line['qtd']);
  }, [line]);

  const debouncedChangeHandler = React.useCallback(
    debounce((index, newQtd) => handleChangeLines(index, newQtd), 300),
    [line]
  );

  const handleQuantityChange = (newQtd) => {
    if (newQtd <= line['available'] && newQtd >= 0) {
      setQtd(newQtd);
      debouncedChangeHandler(index, newQtd);
    } else {
      setQtd(line['available']);
    }
  };

  return (
    <TableRow style={{ backgroundColor: index % 2 > 0 ? '#F1F1F1' : 'inherit' }}>
      <TableCell>{line['id']}</TableCell>
      <TableCell>{line['label']}</TableCell>
      <TableCell align='right'>{line['issued']}</TableCell>
      <TableCell align='left'>{line['planned']}</TableCell>
      <TableCell align='left'>{line['available']}</TableCell>
      <TableCell align='center'>
        <TextField
          type="number"
          variant="outlined"
          onChange={(e) => handleQuantityChange(e.target.value)}
          style={{ minWidth: '10rem' }}
          value={qtd}
          InputProps={{
            inputProps: { style: { textAlign: 'center' } },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  color="warning"
                  disabled={qtd <= 0}
                  onClick={() => handleQuantityChange(qtd - 1)}
                >
                  <RemoveCircleRounded />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="warning"
                  disabled={qtd >= line['available']}
                  onClick={() => handleQuantityChange(qtd + 1)}
                >
                  <AddCircleRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
    </TableRow>
  );
}

LogMatFormModal.defaultProps = {
  open: false,
  onClose: () => { },
  employees: [],
  productionOrders: [],
  employee: null,
};

export default LogMatFormModal;
