import dayjs from "dayjs";
import ExcelJS from "exceljs";

import logo from "./logo.js";

export const languages = [
    { value: 'pt-br', label: 'Português' },
    { value: 'en', label: 'Inglês' },
];

const translations = {
    'title': { 'pt-br': 'Relatório Semanal de Obras', 'en': 'Weekly Work Report' },
    'contractor': { 'pt-br': 'Contratante', 'en': 'Contractor' },
    'client': { 'pt-br': 'Cliente Final', 'en': 'Client' },
    'area': { 'pt-br': 'Área', 'en': 'Area' },
    'project': { 'pt-br': 'Projeto', 'en': 'Project' },
    'local': { 'pt-br': 'Local', 'en': 'Location' },
    'technician': { 'pt-br': 'Técnico', 'en': 'Technician' },
    'timesheet': { 'pt-br': 'Apontamentos', 'en': 'Timesheet' },
    'item': { 'pt-br': 'ITEM', 'en': 'ITEM' },
    'date': { 'pt-br': 'DATA', 'en': 'DATE' },
    'weekday': { 'pt-br': 'DIA SEMANA', 'en': 'WEEKDAY' },
    'start': { 'pt-br': 'HORA INÍCIO', 'en': 'START TIME' },
    'break': { 'pt-br': 'PERÍODO INTERVALO', 'en': 'BREAK' },
    'end': { 'pt-br': 'HORA FIM', 'en': 'END TIME' },
    'field': { 'pt-br': 'TOTAL HORA CAMPO', 'en': 'TOTAL FIELD TIME' },
    'travel': { 'pt-br': 'TOTAL DESLOCAMENTO', 'en': 'TOTAL TRAVEL TIME' },
    'activities': { 'pt-br': 'ATIVIDADES REALIZADAS', 'en': 'ACTIVITIES PERFORMED' },
    'description': { 'pt-br': 'Descrição das Atividades', 'en': 'Activity Description' },
    'signature': { 'pt-br': 'Assinatura do Técnico', 'en': 'Technician Signature' },
    'manager': { 'pt-br': 'Assinatura do Responsável', 'en': 'Manager Signature' },
    'format': { 'pt-br': 'DD/MM/YYYY', 'en': 'MM/DD/YYYY' },
};

/**
 * 
 * @param {Object} form 
 */
export function exportRelatorioApontamentos(form, lang='pt-br') {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Relatorio Apontamentos', {
        pageSetup: { paperSize: 9, fitToPage: true }
    });

    worksheet.columns = [
        { width: 10 },
        { width: 12 },
        { width: 25 },
        { width: 12.7 },
        { width: 12.7 },
        { width: 17 },
        { width: 17 },
        { width: 17 },
    ];

    worksheet.addRow([]);

    worksheet.addRow([translations['title'][lang.value]]);

    const imageLogo = workbook.addImage({
        base64: logo,
        extension: 'png',
    });
    worksheet.addImage(imageLogo, {
        tl: { col: 0, row: 1 },
        ext: { width: 222, height: 40 }
    });

    worksheet.getCell('A2').style = {
        alignment: { horizontal: 'center', vertical: 'center' },
        font: { bold: true, size: 14 },
    };
    worksheet.mergeCells('A2:H2');
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([translations['contractor'][lang.value], '', '']);
    worksheet.mergeCells('A5:B5');
    worksheet.addRow([translations['client'][lang.value], '', '']);
    worksheet.mergeCells('A6:B6');
    worksheet.addRow([translations['area'][lang.value], '', '']);
    worksheet.mergeCells('A7:B7');
    worksheet.addRow([translations['project'][lang.value], '', form.projeto.label]);
    worksheet.mergeCells('A8:B8');
    const local = form.apontamentos.length > 0 ? form.apontamentos[0]['U_Local'] : 0;
    worksheet.addRow([translations['local'][lang.value], '', local]);
    worksheet.mergeCells('A9:B9');
    const tecnico = form.colaborador.FirstName.concat(' ').concat(form.colaborador.LastName);
    worksheet.addRow([translations['technician'][lang.value], '', tecnico]);
    worksheet.mergeCells('A10:B10');
    ['C5', 'C6', 'C7', 'C8', 'C9'].forEach((key) => worksheet.getCell(key).font = { bold: true });
    worksheet.addRow([]);

    const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    const headerRow = worksheet.rowCount + 1;

    worksheet.addTable({
        name: translations['timesheet'][lang.value],
        ref: 'A12',
        headerRow: true,
        style: {
            theme: 'TableStyleLight12',
            showRowStripes: true,
        },
        columns: [
            { name: translations['item'][lang.value] },
            { name: translations['date'][lang.value] },
            { name: translations['weekday'][lang.value] },
            { name: translations['start'][lang.value] },
            { name: translations['break'][lang.value] },
            { name: translations['end'][lang.value] },
            { name: translations['field'][lang.value] },
            { name: translations['travel'][lang.value] },
        ],
        rows: form.apontamentos.map((item, index) => {
            const time = dayjs(item.EffectiveTime, "HH:mm");
            let total = time.hour() + (time.minute() * 10 / 60 / 10);
            return [
                index + 1,
                item.Date.format(translations['format'][lang.value]),
                item.Date.locale(lang).format('dddd'),
                item.StartTime,
                item.Break,
                item.EndTime,
                item.ActivityType != 16 ? total : '',
                item.ActivityType == 16 ? total : '',
            ]
        }),
    });

    cols.forEach((key) => worksheet.getCell(key.concat(headerRow)).style = {
        font: { bold: true },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        fill: { pattern: 'solid', type: 'pattern', fgColor: { argb: 'FFCCCCCC' }, bgColor: { argb: 'FF000000' } },
    })
    worksheet.getRow(headerRow).height = 40;
    form.apontamentos.forEach((_, index) => {
        cols.forEach((key) => worksheet.getCell(key.concat(headerRow + index + 1)).style = {
            alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        });
    });

    worksheet.addRow([]);
    worksheet.getCell('G'.concat(worksheet.rowCount)).value = {
        formula: 'SUM(G'.concat(headerRow + 1).concat(':G').concat(headerRow + form.apontamentos.length).concat(')'),
    };
    worksheet.getCell('H'.concat(worksheet.rowCount)).value = {
        formula: 'SUM(H'.concat(headerRow + 1).concat(':H').concat(headerRow + form.apontamentos.length).concat(')'),
    };
    cols.forEach((key) => worksheet.getCell(key.concat(worksheet.rowCount)).style = {
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
    });

    worksheet.addRow([translations['activities'][lang.value]]);
    let row = worksheet.rowCount;
    cols.forEach((key) => worksheet.getCell(key.concat(row)).style = {
        font: { bold: true },
        fill: { pattern: 'solid', type: 'pattern', fgColor: { argb: 'FFCCCCCC' }, bgColor: { argb: 'FF000000' } },
        alignment: { horizontal: 'center' },
    });
    worksheet.mergeCells('A'.concat(row).concat(':H').concat(row));
    form.apontamentos.forEach((item, index) => {
        worksheet.addRow([
            index + 1,
            item.Date.format(translations['format'][lang.value]),
            translations['description'][lang.value],
        ]);
        let row = worksheet.rowCount;
        worksheet.getCell('A'.concat(row)).alignment = { horizontal: 'center' };
        worksheet.getCell('C'.concat(row)).font = { bold: true };
        worksheet.addRow([item.Activity]);
        cols.forEach((key) => worksheet.getCell(key.concat(worksheet.rowCount)).style = {
            font: { bold: true },
            fill: { pattern: 'solid', type: 'pattern', fgColor: { argb: 'FFCCCCCC' }, bgColor: { argb: 'FF000000' } },
        });
        worksheet.addRow([item.U_EASY_Obs]);
        const start = worksheet.rowCount;
        worksheet.getCell('A'.concat(start)).style = {
            alignment: { horizontal: 'left', vertical: 'top', wrapText: true }
        };
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.mergeCells('A'.concat(start).concat(':H').concat(worksheet.rowCount));
    });
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([translations['technician'][lang.value], '', '', '', translations['manager'][lang.value]]);
    row = worksheet.rowCount;
    worksheet.getCell('A'.concat(row)).alignment = { horizontal: 'center' };
    worksheet.getCell('E'.concat(row)).alignment = { horizontal: 'center' };
    worksheet.mergeCells('A'.concat(row).concat(':D').concat(row));
    worksheet.mergeCells('E'.concat(row).concat(':H').concat(row));
    worksheet.addRow([tecnico, '', '', '', '']);
    row = worksheet.rowCount;
    worksheet.getCell('A'.concat(row)).alignment = { horizontal: 'center' };
    worksheet.getCell('E'.concat(row)).alignment = { horizontal: 'center' };
    worksheet.mergeCells('A'.concat(row).concat(':D').concat(row));
    worksheet.mergeCells('E'.concat(row).concat(':H').concat(row));
    worksheet.pageSetup.printArea = 'A1:H'.concat(row);

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = dayjs().format('YYYY-MM-DD').concat('-RelatorioApontamentos-').concat(form.colaborador.label).concat('.xlsx');
        a.click();
    });
}