import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Backdrop, Box, Button, Card, CircularProgress, FormControl,
  Grid, Icon, InputLabel, TextField, createTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { useStatus } from 'hooks/useStatus';
import dynTheme from 'assets/dynTheme';
import { auth } from '../../firebase/firebase';

function TourView() {
  
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      await auth.authStateReady();
      if (!auth.currentUser) {
        window.alert('Sessão expirada. Por favor, faça login novamente.');
        window.location.href = '/Authentication';
      }
      const tokenId = await auth.currentUser.getIdToken(true);
      setIdToken(tokenId);
    }

    checkToken();
  }, []);

  const tourURL = `https://tour.greylogix.dev?idToken=${idToken}`;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Tour virtual Greylogix
              </MDTypography>
            </Box>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
               {idToken ? (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => window.open(tourURL, '_blank')}
                    >
                      Acessar Tour
                    </Button>
                  ) : (
                    <CircularProgress color='warning' />
                  )
                }
              </Grid>
            </Grid>
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default TourView;
