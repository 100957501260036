import React, { Component } from 'react';
import $ from 'jquery';

import LogModal from 'react-modal';

import { Button, Container } from '@mui/material';
import { Refresh, Add } from '@mui/icons-material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import * as XLSX from "xlsx";

class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  

  render() {
    const { item, children } = this.props;
    const { expanded } = this.state;

    return (
      <div className="tree-item"> 
        <div onClick={this.toggleExpanded}>
          <TableCell align="center" style={{ width: '50px' }}><MDTypography  sx={{ fontSize: '12px' }} variant="h6">{expanded ? <ExpandLess /> : <ExpandMore />}   </MDTypography> </TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.ProductionOrderStatus != null ? item.ProductionOrderStatus : '-'}</MDTypography> </TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.DocumentNumber != null ? item.DocumentNumber : '-'}</MDTypography> </TableCell>
          <TableCell align="center" style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.ItemNo != null ? item.ItemNo : '-'}</MDTypography> </TableCell>
          <TableCell align="center" style={{ width: '150px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.Project != null ? item.Project : '-'}</MDTypography> </TableCell>
          <TableCell align="center" style={{ width: '300px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.ProductDescription != null ? item.ProductDescription : '-'}</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.ClosingDate != null ? item.ClosingDate : '-'}</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.Warehouse != null ? item.Warehouse : '-'}</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.DistributionRule != null ? item.DistributionRule : '-'}</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '150px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.DueDate != null ? item.DueDate : '-'}</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.PlannedQuantity != null ? item.PlannedQuantity : '-'}</MDTypography></TableCell>
          <TableCell align="center" style={{ width: '100px' }}><MDTypography sx={{ fontSize: '12px' }} variant="h6">{item.CompletedQuantity != null ? item.CompletedQuantity : '-'}</MDTypography></TableCell>

       

        </div>
        {expanded && children}
      </div>
    );
  }
}

class ProdOrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProdOrderData: [],
      currentPage: 1,
      isLoading: false,
      showAddForm: false,
      Name: '',
      Description: '',
    };
  }

  handleRefreshButton = (e) => {
    console.log('handleRefreshButton called');

    this.setState(
      { ProdOrderData: [], currentPage: 1 }, // Clear previous data and reset page
      () => {
        console.log(this.state.currentPage);
        this.getProdOrderData();
      }
    );
  };


  exportDataToXLSX = () => {
    const { ProdOrderData } = this.state;

  if (!ProdOrderData || !ProdOrderData.length) {
    alert('No data available to export.');
    return;
  }

  const header = ['DocumentNumber','ProductionOrderStatus','ItemNo','subItemNo','ItemName','BaseQuantity','PlannedQuantity','IssuedQuantity','ItemNo','Warehouse'];
  const data = [];

  ProdOrderData.forEach((item) => {
    item.ProductionOrderLines.forEach((line) => {
      data.push([
        item.DocumentNumber,
        item.ProductionOrderStatus,
        item.ItemNo,  
        line.ItemNo,
        line.ItemName,
        line.BaseQuantity,
        line.PlannedQuantity,
        line.IssuedQuantity ,
        line.ItemNo,
        line.Warehouse

      ]);
    });
  });

  const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Ordensdeprod');
  XLSX.writeFile(wb, 'Ordensdeprod.xlsx');
};

  addProdOrder = () => {
    this.setState({
      showAddForm: true,
    });
  };

  closeAddProdOrder = () => {
    this.setState({
      showAddForm: false,
    });
  };

  componentDidMount() {
    // Set app element for react-modal (must be done once)
    console.log('ProdOrderComponent mounted');
    LogModal.setAppElement('#app'); // Replace '#root' with the appropriate element
    this.getProdOrderData();
  }

  handleAddProdOrderSubmit = () => {
    console.log('Submit button clicked');
    const { sessionId } = this.props;

    const postData = {
      //Name: this.state.Name,
      //Description: this.state.Description,
    };

    $.ajax({
      url: `https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders`,
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        console.log('Request Body:', JSON.stringify(postData, null, 2));

        this.setState({
          showAddForm: false,
        });
      },
      error: (error) => {
        console.error('Error:', error);
        console.log('Request Body:', JSON.stringify(postData, null, 2));
      },
    });
  };

  getProdOrderData = () => {
    const { sessionId } = this.props;
    $("#okAlert").fadeOut();
    $("#errorAlert").fadeOut();
    const { currentPage, isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    const skip = (currentPage - 1) * 20; // Adjust the page size here (20 in this case)
    const apiUrl = `https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders?$orderby=PostingDate desc&$skip=${skip}&$filter=PostingDate%20gt%20%272023-10-01%27%20and%20ProductionOrderStatus%20ne%20%27boposClosed%27%20and%20ProductionOrderStatus%20ne%20%27boposCancelled%27`;
    // Construct the headers
    const headers = {
      Authorization: `Bearer ${sessionId}`,
    };

    $.ajax({
      url: apiUrl,
      type: 'GET',
      headers: headers,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',

      success: (response) => {
        const newData = response.value || [];

        this.setState((prevState) => ({
          ProdOrderData: [...prevState.ProdOrderData, ...newData],
          isLoading: false,
        }));

        if (newData.length === 20) {
          // If you received 20 records, there might be more, so increment the page
          this.setState(
            (prevState) => ({ currentPage: prevState.currentPage + 1 }),
            this.getProdOrderData
          );
        }
      },
      error: (error) => {
        console.error('Error:', error);

        this.setState({ isLoading: false });
      },
    });
  };

  renderTree() {
    const { ProdOrderData, isLoading } = this.state;
  
    if (isLoading) {
      return <p>Loading...</p>;
    }
  

    return (
      <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Ordens de Produção
              </MDTypography>
            </MDBox>   
            <div >
        <TableCell align="center"   style={{ width: '50px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >-</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >PO Status</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Doc Number</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '200px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >ItemNo</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '150px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Project</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '300px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Product Description</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Closing Date</MDTypography></TableCell>
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Warehouse</MDTypography></TableCell>  
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Distrib. Rule</MDTypography></TableCell>       
        <TableCell align="center"   style={{ width: '150px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Due Date</MDTypography></TableCell>  
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Planned Qty</MDTypography></TableCell>  
        <TableCell align="center"   style={{ width: '100px' }}><MDTypography variant="h6" sx={{ fontSize: '12px' }} fontWeight="medium" >Completed Qty</MDTypography></TableCell>  

        </div>
    
  
        {ProdOrderData.map((item, parentIndex) => (
          <TreeNode key={parentIndex} item={item}>

<div >
        <TableCell align="center" style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >Item No</MDTypography></TableCell>
        <TableCell align="center" style={{ width: '400px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >Item Name</MDTypography></TableCell>
        <TableCell align="center" style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >Base Quantity</MDTypography></TableCell>
        <TableCell align="center" style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >Planned Quantity</MDTypography></TableCell>
        <TableCell align="center" style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >Issued Quantity</MDTypography></TableCell>
        <TableCell align="center" style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >Warehouse</MDTypography></TableCell>  
     

        </div>
            {item.ProductionOrderLines.map((line, lineIndex) => (
              <div  key={`${parentIndex}_${lineIndex}`}>
                {/* Display the data in separate columns */}
                <TableCell align="center"   style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemNo != null ? line.ItemNo : '-'}</MDTypography></TableCell>
                <TableCell align="center"   style={{ width: '400px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.ItemName != null ? line.ItemName : '-'}</MDTypography></TableCell>
                <TableCell align="center"   style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.BaseQuantity != null ? line.BaseQuantity : '-'}</MDTypography></TableCell>
                <TableCell align="center"   style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.PlannedQuantity!= null ? line.PlannedQuantity : '-'}</MDTypography></TableCell>
                <TableCell align="center"   style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.IssuedQuantity != null ? line.IssuedQuantity : '-'}</MDTypography></TableCell>
                <TableCell align="center"   style={{ width: '200px' }}><MDTypography sx={{ fontSize: '12px' }} component="a" href="#" variant="button" color="text" fontWeight="medium" >{line.Warehouse != null ? line.Warehouse : '-'}</MDTypography></TableCell>
                
               
                
                
                {/* Add more data fields as needed */}
              </div>
            ))}
          </TreeNode>
        ))}
       </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }
  

  render() {
    return (
      <DashboardLayout>
        <DashboardNavbar />

        <Grid container spacing={1}>

         <Grid item>

              <MDButton variant="gradient" color="warning" onClick={this.handleRefreshButton} >
                <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
                &nbsp;Carregar Ordens
              </MDButton>
              </Grid>

              <Grid item>
              <MDButton sx={{ width: "150px", height: "40px" }} variant="gradient" color="warning" onClick={this.exportDataToXLSX} >
                <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                &nbsp;Exportar
              </MDButton>
            </Grid> </Grid>
        {this.renderTree()}

        



        <LogModal
          isOpen={this.state.showAddForm}
          onRequestClose={this.closeAddProdOrder}
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div>
            <h2>Adicionar ProdOrdero</h2>
            <label>Name:</label>
            <input
              type="text"
              value={this.state.Name || ''}
              onChange={(e) => this.setState({ Name: e.target.value })}
              placeholder=""
              className="black-input"
            />
            <div>
              <label>Description:</label>
              <input
                type="text"
                value={this.state.Description || ''}
                onChange={(e) => this.setState({ Description: e.target.value })}
                placeholder=""
                className="black-input"
              />
            </div>
            <button className="button" onClick={this.handleAddProdOrderSubmit}>
              Submeter
            </button>
            <button className="button" onClick={this.closeAddProdOrder}>
              Cancelar
            </button>
          </div>
        </LogModal>
        <Footer/>
</DashboardLayout >
    );
  }
}

export default ProdOrderComponent;

