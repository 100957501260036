import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

export function exportEmployeesToXLSX(data) {
  const header = [
    'EmployeeID',
    'EmployeeCode',
    'LastName',
    'FirstName',
    'MiddleName',
    'JobTitle',
    'eMail',
    'CostCenterCode',
    'Active',
    'U_Portal_Role',
    'U_EASY_ApontaEmLote',
  ];
  const rows = [];

  data.forEach((item) => {
    rows.push([
      item['EmployeeID'],
      item['EmployeeCode'],
      item['LastName'],
      item['FirstName'],
      item['MiddleName'],
      item['JobTitle'],
      item['eMail'],
      item['CostCenterCode'],
      item['Active'],
      item['U_Portal_Role'],
      item['U_EASY_ApontaEmLote'],
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Colaboradores');
  XLSX.writeFile(wb, `${dayjs().format('YYYY-MM-DD-HH-mm')}-Colaboradores.xlsx`);
};
